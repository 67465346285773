//
//  Footer
//  ---------------------------------------------
.footer.content {
    max-width: 1440px;
    margin: 120px auto 0 auto;
    font-size: 1.4rem;

    ul {
        @extend .abs-reset-list;
    }

    .footer__top {
        display: flex;
        justify-content: space-between;

        .logo {
            display: inline-block;
            float: none;
            margin: 0 auto;
            width: 100%;

            img {
                margin: 0 auto;
            }
        }
    }

    .footer__content {
        max-width: 375px;
        width: 100%;

        p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #1c2028;
        }
    }

    .footer__links {
        width: 100%;

        h3 {
            font-size: 1.4rem;
            color: $c-bluey-grey;
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 1px;
            position: relative;

            @include max-screen($screen__m) {
                padding: 0 45px;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: 0.5px;
                color: $c-dark;
            }

            .arrow-down,
            .arrow-up {
                display: none;

                @include max-screen($screen__m) {
                    display: block;
                }
            }

            .arrow-down {
                @include icomoon("arrow-down", "before", true, true) {
                    font-size: 18px;
                    color: $c-dark;
                }
            }

            .arrow-up {
                @include icomoon("arrow-down", "before", true, true) {
                    font-size: 18px;
                    color: $c-dark;
                }
            }

            .arrow-down:before,
            .arrow-up:before {
                display: inline-block;
                position: absolute;
                right: 45px;
                top: 50%;
                transform: translateY(-50%);
            }

            .arrow-up:before {
                display: inline-block;
                transform: translateY(-50%) rotate(180deg);
            }
        }

        ul.footer__links-first-level {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding-left: 130px;
            width: 100%;

            ul {
                margin-top: 20px;
                line-height: 2;
                padding-right: 15px;
                white-space: nowrap;

                @include max-screen($screen__m) {
                    padding: 0 45px;
                }
            }
        }
    }

    .footer__bottom {
        display: flex;
        align-items: center;
        margin-top: 120px;
    }

    .footer__small-links {

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                margin-right: 15px;
            }
        }

        a {
            color: $c-bluey-grey;
            white-space: nowrap;
            line-height: 2;
        }
    }

    .footer__social {
        background-color: $c-dark;
        padding: 20px 40px;
        margin: 0 30px 0 auto;

        ul {
            display: flex;

            a {
                padding: 20px 15px;
                display: block;

                &.icon:before {
                    color: $c-white;
                    font-size: 16px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .copyright {
        padding: 30px 0;
        color: $c-bluey-grey;
        font-size: 14px;
    }

    @include max-screen($screen__m) {

        .footer__top {
            flex-direction: column;
        }

        .footer__content {
            max-width: none;
            text-align: center;

            .logo,
            .logo img {
                margin: 0 auto;
            }

            p {
                margin-top: 30px;
            }
        }

        .footer__links {

            ul.footer__links-first-level {
                padding-left: 0;
                flex-wrap: wrap;
                justify-content: space-around;

                > li {
                    margin-top: 15px;
                    @media screen and (max-width: $screen__m) {
                        width: 100%;
                    }
                }
            }
        }

        .footer__bottom {
            flex-direction: column;
            margin-top: 60px;
        }

        .footer__social {
            order: 10;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
