body {
    &.navigation-mobile-open {
        overflow-y: hidden;
        height: 100vh;
        position: relative;

        .navigation-main-wrapper {
            .navigation {
                left: 0;
            }
        }
    }

    &.navigation-mobile-loaded {

        .page-header {
            visibility: visible;
        }
    }

    .navigation-mobile-menu {

        @media screen and (max-width: $screen--l) {
            display: flex;
        }

        .navigation-mobile-open & {
            display: none;
        }
    }

    .navigation-mobile-close {

        .navigation-mobile-open & {
            display: inline-block;
        }
    }

    .header.content .navigation > ul > li.navigation-static-link > a, a.level-top.shop-online>span {
        margin: 0 15px;
    }
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    transform: none !important;
    border-bottom: none;

    body.navigation-hover &,
    body.search-opened &{

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 200vh;
            background-color: rgba(28, 32, 40, 0.6);
            z-index: 5;
        }
    }
}

body.search-opened {
    overflow-x: hidden;
}

.top-header-message {
    background-color: $c-dark-green;
    display: none;

    &.opened {
        display: block;
    }

    .wrapper {
        max-width: 1440px;
        padding: 0 20px;
        margin: 0 auto;
        text-align: center;
        color: $c_white;
        font-size: 14px;
        line-height: 40px;

        @media screen and (max-width: $screen__l) {
            font-size: 12px;
            line-height: 30px;
        }

        a {
            color: $c-white;
            text-decoration: underline;
        }
    }

    &__close {
        float: right;
        cursor: pointer;
        line-height: 40px;
        @include icomoon("close", "before", true, true) {
            font-size: 16px;
            color: $c-white;
        }

        svg {
            vertical-align: middle;
        }

        @media screen and (max-width: $screen__l) {
            line-height: 30px;
        }
    }

    &-opened {
        margin-top: 160px;

        @media screen and (max-width: $screen__l) {
            margin-top: 90px;
        }
    }
}

.panel.wrapper {
    background-color: $c-darkblue-bg;
}

.panel.header {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .action {
        display: none;
    }

    .top-nav {
        list-style-type: none;
        display: flex;
        padding: 0;
        margin-bottom: 0;

        li {
            margin-bottom: 0;
        }

        li + li {
            margin-left: 40px;
        }

        a {
            color: $c-white;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }

        &--left {
            flex-grow: 1;
        }

        &__link {
            display: flex;
            align-items: center;

            &--contact,
            &--our-stores {

                &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    display: block;
                    background-size: 12px;
                    background-repeat: no-repeat;
                    margin-right: 6px;
                }
            }

            &--contact {

                &:before {
                    background-image: url('../images/icons/contact.svg');
                }
            }

            &--our-stores {

                &:before {
                    background-image: url('../images/icons/locate.svg');
                }
            }

            @media screen and (max-width: $screen--l) {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: $screen--l) {
        display: none;
    }
}
.page-header {

    .switcher-language {
        order: 7;

        @media screen and (max-width: $screen__l) {
            display: none;
        }

        .switcher-label {
            display: none;
        }

        .switcher-options {
            @include icomoon("simple-arrow-down", "before", true, true) {
                font-size: 14px;
                color: $c-dark;
            }

            &:before {
                position: absolute;
                right: 0;
                top: 5px;
                display: none;
            }

            .switcher-trigger {
                display: none;
            }

            .switcher-dropdown {
                display: block;
                position: static;
                margin-top: 0;
                margin-left: 20px;
                padding: 0;
                min-width: 0;
                background-color: transparent;
                border: none;
                box-shadow: none;
                list-style-type: none;
                margin-bottom: 0;

                &:after,
                &:before {
                    content: initial;
                }

                .switcher-option {
                    margin-bottom: 0;

                    a {
                        color: $c-dark;
                        font-size: 16px;
                        font-weight: 500;
                        text-transform: uppercase;

                        .desktop {
                            display: block;

                            @media screen and (max-width: $screen__l) {
                               display: none;
                            }
                        }

                        .mobile {
                            display: none;

                            @media screen and (max-width: $screen__l) {
                                display: block;
                            }
                        }
                    }
                }

                li {

                    &:hover {
                        background-color: transparent;
                    }
                }

                a {
                    padding: 0;
                    color: $c-dark;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover {
                        background-color: transparent;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $screen__l) {
        .switcher {
            border: 1px solid $c-pale-lilac;
            border-radius: 3px;
            font-size: 1.6rem;
            font-weight: normal;
            padding: 16px 12px;
            margin-top: 40px;
            width: 100%;
            box-sizing: border-box;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }
        }

        .switcher-trigger {
            display: block !important;

            strong {
                position: relative;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1c2028;

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }
        }
        .switcher-dropdown {
            display: none !important;
            padding: $indent__s 0;
        }
        .switcher-options {
            position: relative;

            &:before {
                display: block !important;
            }

            &.active {
                &:before {
                    transform: rotate(180deg);
                }

                .switcher-dropdown {
                    display: block !important;
                    margin: 10px 0;

                    .switcher-option a {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-transform: none;
                    }
                }
            }
        }
    }
}
.header.content {
    position: initial;
    display: flex;
    align-items: center;
    padding-top: 0;
    height: 120px;
    max-width: 1440px;

    @media screen and (min-width: 1221px) {
        padding: 0 40px !important;
    }

    @media screen and (max-width: 1220px) {
        padding: 0 20px !important;
    }

    .logo {
        margin: 0;       

        a {
            display: block;

            svg {
                width: 87px;
                height: auto;
            }
        }
    }

    .header-logo {
        @media screen and (max-width: $screen__l) {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0 -20px;
        }
    }

    .find-us-wrapper {
        order: 9;
        list-style: none;
        margin: -5px 10px 0 15px;
        padding: 0;

        @media screen and (max-width: $screen__l) {
            margin: 0;
            display: none;
        }

            li {
            margin: 0;
        }

        .find-us {
            font-size: 0;

            @include icomoon("location", "before", true, true) {
                font-size: 18px;
                color: $c-dark;
            }

            @media screen and (max-width: $screen__l) {
                font-size: 14px;
                color: $c-dark;
                margin: 23px 0 38px 0;
                padding: 0;

                &:before {
                    display: none;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .block-search {
        position: initial;
        width: auto;
        order: 10;
        margin-right: 28px;
        margin-top: 0;
        z-index: 6;

        .control {
            padding: 0;
            border-top: 0;
        }

        .control-field {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            padding: 0 40px;
            background-color: $c_bg-grey;
            align-items: center;
            display: none;

            &:before {
                content: '';
                background-color: $c_bg-grey;
                width: 100%;
                height: 55px;
                position: absolute;
                left: -100%;
                top: 0;
            }

            &:after {
                content: '';
                background-color: $c_bg-grey;
                width: 100%;
                height: 55px;
                position: absolute;
                right: -100%;
                top: 0;
            }

            .label {
                float: none;
                width: auto;
                height: auto;
                clip: auto;
                position: unset;

                @include icomoon("search", "before", true, true) {
                    font-size: 20px;
                    color: $c_text-lighter;
                }

                &:before {
                    text-align: left;
                    padding-right: 16px;
                    border-right: solid 1px $c_text-lighter;

                    @media screen and (max-width: $screen__l) {
                        border: none;
                        padding-right: 4px;
                        color: $c-bluey-grey;
                    }
                }
            }

            input {
                padding: 0 0 0 59px;
                border: none;
                font-size: 14px;
                background-color: $c_bg-grey;
                color: $c-dark;
                box-shadow: none;
                height: 55px;
                line-height: 55px;
                text-align: left;

                &::placeholder {
                    color: $c-dark !important;
                }
            }

            &.active {
                display: flex;
            }
        }

        .action.search {
            position: initial;
            padding: 17px 20px;
        }

        .action.search-box-toggle {
            padding: 0;
        }

        .minisearch {
            .open-icon,
            .close-icon {
                width: 20px;
                height: 20px;
            }
            .actions {
                cursor: pointer;

                .action.search[disabled] {
                    opacity: 1;
                }
                .action.search {
                    @include icomoon("search", "before", true, true) {
                        font-size: 20px;
                        color: $c-black;
                    }

                    @media screen and (max-width: $screen--l) {
                        &:before {
                            display: none;
                        }
                    }

                }
            }

            .open-icon {

            }
            .close-icon {
                display: none;
            }

            .search-opened & {

                .actions {
                    position: relative;
                    .action.search {
                        @include icomoon("close")
                    }
                }

                .open-icon {
                    display: none;
                }

                .close-icon {
                    display: block;
                }

                .control-field {
                    display: flex;
                }
            }
        }

        .search-autocomplete {
            display: none !important;
        }

        @media screen and (max-width: $screen--l) {
            display: none;

            .control {
                margin: 0;
            }
        }
    }

    .header.misc {
        order: 10;

        @media screen and (max-width: $screen--l) {
            display: none;
        }
    }

    .minicart-wrapper {
        order: 11;
        margin-left: 27px;

        &:before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 1px;
            background: #e9e9eb;
            margin-right: 22px;
            vertical-align: middle;

            @media screen and (max-width: $screen__l) {
                display: none;
            }
        }

        .action.showcart {
            vertical-align: middle;

            .qty {
                background-color: transparent;
                vertical-align: middle;
                font-size: 14px;
                text-align: center;
                margin: 0;
                padding: 0 0 0 3px;
                color: $c-primary;

                &.empty {

                    &:before,
                    &:after {
                        content: none;
                    }
                }

                .loading-mask {
                    display: none;
                }
            }

            &:before {
                content: '';
                background-image: url('../images/icons/cart.svg');
                width: 20px;
                height: 20px;
                background-size: 20px;
                vertical-align: middle;
            }
        }

        .minicart-title {
            display: none;
        }

        .block-minicart {
            border: none;
            top: 52px;
            width: 320px;
            padding: 0;

            &:before,
            &:after {
                content: none;
            }
        }

        .block-content {
            padding-top: 27px;

            .action.close {
                display: none;
            }

            .items-total {
                margin-left: 0;
                margin-bottom: 18px;
                padding-left: 30px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                float: none;

                .count {
                    font-weight: 500;

                    &:before {
                        content: '(';
                    }

                    &:after {
                        content: ')';
                    }
                }
            }

            .minicart-items {
                .product-item {
                    padding: 15px 0;

                    > .product {
                        .product-item-details {
                            @include lib-vendor-prefix-display(flex);
                            justify-content: space-between;
                            align-items: flex-start;
                            padding-left: 12px;

                            .left-block {
                                padding-right: 15px;
                                width: 80%;

                                .product-item-name {
                                    padding: 0;
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 15px;
                                    margin-bottom: 6px;

                                     a {
                                         text-transform: none;
                                         text-decoration: none;
                                     }
                                }

                                .details-qty {
                                    margin-top: 6px;

                                    > .label,
                                    > .control {
                                        display: inline-block;
                                        color: $c-black;
                                        font-size: 12px;
                                        letter-spacing: 0;
                                        line-height: 15px;
                                        font-weight: normal;
                                    }
                                }
                            }

                            .right-block {
                                .product-item-pricing {
                                    .price {
                                        font-size: 12px;
                                        letter-spacing: 0;
                                        line-height: 15px;
                                        text-align: right;
                                        font-weight: normal;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .subtotal {
                @include lib-vendor-prefix-display(flex);
                justify-content: space-between;
                align-items: center;
                padding: 0 30px;
                margin: 0;

                > .label,
                > .amount.price-container {
                    display: inline-block;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                }

                > .amount.price-container .price {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .actions {
                padding-left: 30px;
                padding-right: 30px;
                margin-top: 25px;

                > .primary {
                    box-sizing: border-box;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 1.5px;
                    line-height: 14px;
                    text-align: center;
                    height: 50px;
                }

                > .secondary {
                    margin: 30px 0;

                    .viewcart {
                        display: block;
                        text-align: center;
                        color: #BFC9CD;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;

                        &:hover {
                            color: $c-text-dark
                        }
                    }
                }

                .action.primary {
                    line-height: 1.2;
                    font-size: 13px;
                    text-transform: uppercase;
                    color: $c-white;
                    background-color: $c-primary;
                    border: none;


                    &:hover {
                        background-color: $c-primary-darker;
                    }
                }
            }
        }

        @media screen and (max-width: $screen--l) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    .navigation-projects {
        border-radius: 1px;
        border: solid 1px $c_primary;
        order: 13;
        padding: 14px 16px;
        margin-left: 20px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        text-align: center;
        color: $c_primary;
        vertical-align: middle;
        line-height: 1rem;

        &:hover {
            text-decoration: none;
        }

        @media screen and (max-width: 1220px) {
            padding: 14px;
            font-size: 10px;
            margin-left: 25px;
        }

        @media screen and (max-width: $screen__l) {
            display: none;
        }

        &.rdv {
            background-color: #567E5F;
            color: #ffffff;
        }
    }

    .navigation {
        .navigation-projects {
            display: block;

            @media screen and (max-width: $screen__l) {
                order: unset;
                margin: 40px 0 50px 0;
                width: 100%;
                box-sizing: border-box;
                padding: 18px 29px;
            }
        }

        .find-us-wrapper {
            display: block;

            @media screen and (max-width: $screen__l) {
                font-weight: normal;

                li a {
                    margin: 23px 0 38px 0;
                    display: inline-block;
                }
            }
        }

        .navigation-shop-tools {
            order: 10;
            background: $c_bg-grey;
            padding: 22px;
            margin-right: 1%;

            @media screen and (max-width: $screen__l) {
                margin-bottom: 20px;
            }

            a {
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: 1px;
                color: $c-dark-green;

                &:not(:last-child) {
                    padding: 0 0 20px 0;
                }

                &:first-child {
                    padding: 0 0 10px;
                }

                &:last-child {
                    padding: 0;
                }

                &:hover {
                    background: none;
                }

                @media screen and (max-width: $screen__l) {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .navigation-mobile {

        &-menu,
        &-close {
            display: none;
        }

        @media screen and (max-width: $screen--l) {

            &-menu {
                display: block;
                order: -1;
                z-index: 10;
            }
        }
    }

    .navigation-main-wrapper {
        height: 100%;
        flex-grow: 1;
        margin-left: 25px;

        @media screen and (max-width: $screen__l) {
            margin-left: 0;

            .top-header-message-opened & {
                @media screen and (max-width: $screen__l) {
                    top: -30px;
                }
            }

            .switcher-language {
                display: block;
            }
        }

        @media screen and (max-width: $screen__l) {
            position: absolute;
            top: 0;
            left: 0;
            width: 1000vw;
            height: 100vh;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: initial;
            overflow-y: scroll;
            overflow-x: hidden;
            z-index: -9999;
            display: block;
            background-color: transparent;
            transition: transform .24s ease-in;
            transition-delay: .24s;
            pointer-events: none;

            .navigation-mobile-open & {
                z-index: 9999;
                background-color: rgba(28, 32, 40, 0.8);
            }

            .navigation-mobile-close {
                display: inline-block;
                position: fixed;
                left: 335px;
                top: 10px;
                width: 65px;
                height: 60px;
                z-index: 20;
                pointer-events: all;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    .navigation {
        position: static;
        flex-grow: 1;
        display: flex;
        background-color: transparent;
        transition: background-color .24s ease-in;
        transition-delay: .24s;
        margin-left: 25px;

        > ul {
            position: static;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            > li.level0,
            > li.navigation-static-link {
                position: static;
                height: 100%;
                display: flex;
                align-items: stretch;
                margin: 0;
                border-top: 0;

                > a {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $c_primary;
                    text-transform: uppercase;
                    margin: 0 15px;
                    cursor: pointer;

                    @media screen and (max-width: 1220px) {
                        margin: 0 15px;
                    }

                        > span {
                        display: block;
                        margin-left: 0 !important;
                    }

                    &:hover {
                        color: $c_black;
                        text-decoration: none;
                    }

                    &.level-top {
                        border: none !important;
                    }
                }

                &--highlight {

                    > a {
                        color: $c-primary;

                        &:hover {
                            color: $c_primary;
                        }
                    }
                }

                > ul {
                    display: none;
                }

                ul {
                    box-shadow: none;
                    border: none;
                }

                &.has-active,
                &.active,
                &:hover {

                    > a {
                        height: 100%;
                        position: relative;
                        border: none !important;

                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            height: 4px;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background-color: $c-primary;
                        }
                    }
                }

                &:hover,
                &.navigation-mobile-touch {

                    > ul {
                        background-color: $c-white;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        width: 96%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: space-between;
                        padding-top: 40px;
                        padding-bottom: 40px;
                        z-index: 10;
                        border: none;
                        box-shadow: inset 0 5px 7px -5px rgba(0, 0, 0, 0.05);

                        &.level0.submenu {
                            > li {
                                padding: 0 1%;

                                @media screen and (max-width: $screen__l) {
                                    padding: 0;
                                    margin: 0 10px;
                                }
                            }
                        }

                        &.category-inspirations,
                        &.category-material {
                            @media screen and (max-width: $screen__l) {
                                padding-top: 0;
                                padding-bottom: 0;
                            }

                            > li {
                                flex: 1;
                                max-width: 300px;
                                margin: 0 20px;

                                @media screen and (max-width: $screen__l) {
                                    padding: 0;

                                    > a > img {
                                        display: none;
                                    }
                                }

                                > a:hover {
                                    text-decoration: none;
                                }

                                > a > span {
                                    display: block;
                                    padding: 28px 0 41px 0;
                                    border-bottom: solid 1px #979797;
                                    font-size: 14px;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    text-align: left;
                                    color: $c_primary;

                                    @media screen and (max-width: $screen__l) {
                                        border: none;
                                        font-size: 14px;
                                        font-weight: 500;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: 1.14;
                                        letter-spacing: 1px;
                                        color: $c-cloudy-blue;
                                        padding: 7px 0;
                                    }
                                }
                            }
                        }

                        > li {
                            margin-bottom: 0;
                        }

                        li {
                            a {
                                color: $c-black;
                                padding: 0;
                                font-weight: 400;

                                &:hover {
                                    background-color: transparent;
                                    color: $c-text-dark;
                                }
                            }
                        }

                        &:before,
                        &:after {
                            display: block;
                            content: '';
                            top: 0;
                            position: absolute;
                            width: 100vw;
                            height: 100%;
                            background-color: $c-white;
                            box-shadow: inset 0 5px 7px -5px rgba(0, 0, 0, 0.05);
                        }

                        &:before {
                            left: 99%;
                        }

                        &:after {
                            right: 99%;
                        }
                    }

                    li.level1 {
                        min-width: 172px;

                        > a {
                            margin-bottom: 32px;
                            font-size: 14px;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.14;
                            letter-spacing: 1px;
                            color: $c-dark-green;
                            font-weight: 600;

                            @media screen and (max-width: $screen__l) {
                                font-size: 14px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.14;
                                letter-spacing: 1px;
                                color: $c-cloudy-blue;
                            }
                        }
                    }

                    ul.level1 {
                        position: static;
                        display: block;
                        min-width: 0;
                        padding: 0;
                    }

                    li.level1.last {
                        display: none;
                    }

                    li.level2 {
                        margin-bottom: 10px;

                        > a {
                            margin-bottom: 12px;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.14;
                            letter-spacing: normal;
                            color: #1c2028;
                        }
                    }

                    ul.level2 {
                        display: none;
                    }

                }

                &.navigation-mobile-touch {

                    > ul {
                        padding-top: 8px;
                        height: auto !important;
                        box-shadow: none;
                    }
                }
            }
        }

        .level0.level-top:not(.parent) {
            display: none;
        }

        .level0 .submenu {
            .active > a {
                border-style: none;
                border-width: 0;
            }
        }

    @media screen and (max-width: $screen--l) {
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #fff;
        width: 320px;
        height: 100vh;
        padding: 0 20px;
        margin: 0;
        font-weight: normal;
        //overflow-y: scroll;
        padding-bottom: 80px;
        left: -320px;
        transition: left .24s ease-in;
        pointer-events: all;
        overflow: scroll;
        overflow-x: hidden;

        .block-search,
        .navigation-main,
        .header.links,
        .panel.header {
            width: 100%;
        }

        .block-search {
            display: block;
            order: initial;
            background-color: #fff;
            padding-left: 0;

            @media screen and (max-width: $screen__l) {
                margin: 0 -20px;
                position: unset;
            }

            .minisearch {
                position: relative;

                .actions {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 12px;
                    height: 18px;
                    width: 18px;
                    pointer-events: none;

                    .open-icon {
                        width: 18px;
                        height: 18px;

                        path {
                            fill: rgba(140, 150, 169, .6);
                        }
                    }
                }
            }

            .control-field {
                position: static;
                display: flex !important;
                align-items: center;
                padding: 0 40px;
                height: 60px;

                @media screen and (max-width: $screen__l) {
                    padding: 0 20px;
                    width: 100%;
                }

                input {
                    position: static;
                    text-align: left;
                    padding: 0;
                    font-size: 14px;
                    left: auto;
                    margin: 0;
                    margin-left: 10px;
                    line-height: 1.71;
                    color: $c-dark;
                }
            }
        }

        > ul {
            display: inline-block;
            width: 100%;
            height: auto;
            background-color: #fff;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 15px;



            li.level0,
            li.navigation-static-link {
                width: 100%;
                margin-right: 0;
                flex-direction: column;
                align-items: flex-start;
                height: auto;

                @media screen and (max-width: $screen__l) {
                    &:last-child {
                        border-bottom: none;
                    }
                }


                &.has-active,
                &:hover {

                    > .level-top {
                        border: none !important;
                        height: auto;
                    }

                    > ul {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                > a {
                    padding: 17px 60px 17px 13px;
                    display: block;
                    width: 100%;
                    position: relative;

                    @media screen and (max-width: $screen__l) {
                        margin: 0;
                        padding: 20px 0;
                        box-sizing: border-box;
                        text-align: left;

                        span {
                            margin: 0 20px;
                            display: inline-block;
                        }
                    }

                    &:before {
                        position: absolute;
                        display: block;
                        content: '+';
                        font-size: 22px;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                    }

                    &:after {
                        content: none !important;
                    }
                }

                ul.level0 {
                    height: 0;
                    position: static;
                    display: flex !important;
                    flex-direction: column;
                    transform: none;
                    overflow: hidden !important;
                    padding-left: 40px;

                    @media screen and (max-width: $screen__l) {
                        padding-left: 0;
                        display: block !important;
                    }

                    li.level1 {
                        width: 100%;

                        a {
                            margin-bottom: 14px;
                        }
                    }

                    &:before,
                    &:after {
                        content: none;
                    }

                    ul.level1 {
                        padding-top: 6px;
                        padding-left: 20px;
                        padding-bottom: 10px;
                    }
                }

                &.navigation-mobile-touch {

                    > a {

                        &:before {
                            content: '-';
                        }
                    }

                    > ul {
                        height: auto;
                        padding-top: 6px;
                    }
                }
            }
        }

        .header.misc {
            display: block;
        }

        .header.links {
            padding-left: 0;
            display: inline-block;

            .authorization-link {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 1px;
                    width: 100px;
                }

                a {
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 25px 0;
                }

                svg {
                    display: none;
                }

                @media screen and (max-width: $screen__l) {

                    > a {
                        padding: 0;
                        text-transform: none;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: $c-dark;


                        > span {
                            display: inline-block;
                            padding: 38px 0;
                            border-top: solid 1px #e9e9eb;
                            border-bottom: solid 1px #e9e9eb;
                        }
                    }
                }
            }
        }

        .panel.header {
            position: relative;
            display: inline-block;
            background-color: #fff;
            align-items: flex-start;
            margin: 0;
            padding-top: 30px;
            padding-left: 20px;

            ul {
                display: flex;
                flex-direction: column;
                margin-left: 0 !important;
                margin-bottom: 20px;

                li {

                    a {
                        padding: 0;
                        font-weight: 400;
                        color: #000;
                        font-size: 14px;

                        &:before {
                            display: none;
                        }
                    }
                }

                li + li {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 20px;
                height: 1px;
                width: 100px;
                background-color: $c_text-lighter;
            }
        }
    }

    @media screen and (max-width: $screen--l) {
        z-index: 10;
    }
}

.navigation .header.links {
    @media screen and (max-width: $screen__l) {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        position: relative;
        padding: 38px 0 10px 0;

        > .authorization-link {
            &:not(:last-of-type) {
                display: none;
            }
        }

        &:not(:first-of-type) {
            &:before,
            &:after {
                content: '';
                background: $c-pale-lilac;
                height: 1px;
                width: 80px;
                position: absolute;
            }

            &:before {
                top: 0;
                left: 0;
            }

            &:after {
                bottom: 0;
                left: 0;
            }
        }

        li {
            padding: 0 !important;
            margin-bottom: 28px !important;

            a {
                padding: 0;
                color: $c-dark;
            }

            &:nth-child(1) {
                order: 3;

            }

            &:nth-child(6) {
                order: 1;
            }

            &.wishlist {
                order: 2;
            }

            &:nth-child(7) {
                order: 5;
            }
        }

        .customer-menu {
            > .header.links {
                padding: 0;
            }
        }

        .greet.welcome {
            span {
                font-size: 24px;
                font-weight: normal;
                line-height: 1.17;
                letter-spacing: 0.5px;
            }
        }

        .action.close {
            display: none;
        }

        .authorization-link {
            order: 10;
            margin-bottom: 28px !important;

            > a > span {
                padding: 0;
                border: none;
            }
        }
    }
}


.header.misc {

    .header.links {
        position: relative;
        list-style-type: none;
        padding: 0;
        margin: 0;

        > .authorization-link {
            margin-bottom: 0;

            > a {
                text-decoration: none;

                @include icomoon("profil", "before", true, true) {
                    color: $c-black;
                    font-size: 20px;
                }
                span {
                    display: none;
                }

                &[href*="logout"] {
                    pointer-events: none;
                }
            }
        }

        .customer-welcome {
            width: 20px;
            height: 35px;
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 10;
            transform: translateX(-50%);
            background-color: $c-white;
            border: none;

            @include icomoon("profil", "before", true, true) {
                color: $c-black;
                font-size: 20px;
            }

            @media screen and (min-width: $screen__l) {
                + .authorization-link {
                    display: none;
                }

                .customer-name {
                    text-transform: uppercase;
                }

                .customer-menu {
                    display: none;
                    position: absolute;
                    top: 35px;
                    right: -12px;
                    background-color: $c-white;
                    border: 1px solid $c-dark;
                    box-shadow: 0 5px 5px -1px rgba($c-dark, 0.5);
                    white-space: nowrap;
                    padding: 15px;
                    width: 110px;

                    .header.links {
                        margin-left: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        max-width: 140px;
                        width: 100%;
                        position: relative;

                        li {
                            margin-bottom: 19px;

                            &:nth-child(1) {
                                order: 3;
                            }

                            &:nth-child(2) {
                                order: 2;
                                position: relative;
                                padding-bottom: 15px;

                                &:after {
                                    content: '';
                                    width: 20px;
                                    height: 1px;
                                    background: #e9e9eb;
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }

                            &:nth-child(3) {
                                order: 5;
                                margin-bottom: 0;
                            }

                            &:nth-child(6),
                            &:nth-child(9) {
                                order: 4;
                            }

                            &:nth-child(8) {
                                order: 1;
                            }
                        }

                        a {
                            color: $c-dark;
                            font-size: 14px;

                            &:hover {
                                color: $c-text-light;
                            }
                        }

                        .authorization-link {
                            order: 10;

                            > a {

                                span {
                                    display: flex;
                                }

                                svg {
                                    display: none;
                                }

                                &[href*="logout"] {
                                    pointer-events: all;
                                }

                                &:before {
                                    display: none;
                                }
                            }
                        }

                        .greet.welcome,
                        .action.close {
                            display: none;
                        }

                    }
                }

                &:hover {

                    .customer-menu {
                        display: block;

                        &:after {
                            content: '';
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-bottom: 6px solid #1c2028;
                            right: 15px;
                            top: -7px;
                            position: absolute;
                        }
                    }
                }
            }

        }
        body.customer-opened & {

            .customer-welcome {
                display: flex;
            }
        }

    }
}

.minicart-wrapper {
    .block-minicart {
        border: solid 1px $c-dark;
        background: $c-white;
        width: 395px;
        top: 30px;
        right: -25px;

        #minicart-content-wrapper {
            position: relative;

            &:after {
                content: '';
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $c-dark;
                left: 354px;
                top: -7px;
                position: absolute;
            }
        }

        .subtitle.empty {
            padding-top: 0;
            padding-bottom: 27px;
        }
    }
}

.minicart-items-wrapper {
    background-color: $c-white;
    margin: 0;
    padding: 20px 30px;
    border: none;
    max-height: 300px;
    overflow-y: scroll;

    .item.product {
        border: none;

        .product-item-details {

            .product-item-name {
                margin-bottom: 6px;

                a {
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: uppercase;
                    color: $c-black;

                    &:hover {
                        color: $c-black;
                    }
                }
            }

            .product.options {

                span.toggle {
                    display: none;
                }

                div.content {
                    display: block !important;
                }
            }

            .product.options.list {
                margin-bottom: 0;

                .label,
                .values {
                    color: $c-black;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 15px;
                    display: inline-block;
                    font-weight: normal;
                    padding: 0;
                    margin: 0;
                }

                .label {
                    &:after {
                        content: ': ';
                    }
                }

                .values {
                    padding-left: 5px;
                }
            }

            .product-item-pricing {

                .price-excluding-tax {
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 12px;
                    margin-top: 0;
                }

                .details-qty {

                    .label {
                        display: none;
                    }

                    .update-cart-item {
                        padding: 0;
                        margin-top: 4px;
                        font-size: 12px;
                        text-decoration: underline;
                        color: $c-text-light;
                        border: none;
                        background-color: transparent;
                    }

                    input {
                        border-width: 1px;
                        border-radius: 2px;
                        font-size: 14px;
                    }
                }
            }

            .product.actions {
                margin: 0;
                padding-right: 0;

                .primary {
                    display: none;
                }

                .secondary {

                    a {

                        &:before {
                            content: '';
                            background-image: url('../images/icons/close.svg');
                            width: 16px;
                            height: 16px;
                            background-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen--l) {
    justify-content: space-between;
    height: 60px;
}
}
