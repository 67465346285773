//
//  Variables
//  _____________________________________________

$checkout-payment-option-title__border            : $checkout-payment-method-title__border !default;
$checkout-payment-option-title__color             : $link__color !default;
$checkout-payment-option-title__padding           : $checkout-payment-method-title__padding !default;
$checkout-payment-option-title-mobile__padding    : $checkout-payment-method-title-mobile__padding !default;

$checkout-payment-option-title-icon__font-size    : 32px !default;
$checkout-payment-option-title-icon__line-height  : 16px !default;
$checkout-payment-option-title-icon__margin       : 0 !default;
$checkout-payment-option-title-icon__color        : $minicart-icons-color !default;
$checkout-payment-option-title-icon__hover__color : $primary__color !default;

$checkout-payment-option-content__padding__xl     : $checkout-payment-method-content__padding__xl !default;

.opc-payment {

    // Payments square trigger
    .payment-trigger {

        .step-title {
            display: none;
        }

        .payment-method-content {
            display: none;
        }

        .payment-trigger-list {
            display: flex;
            justify-content: center;
            margin: 20px -5px 0;

            .payment-method {
                flex-basis: 100%;
                align-self: stretch;

                input[type="radio"] {
                    display: none;
                }

                .payment-method-title {
                    padding: 0;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;
                    box-shadow: 0 0 0 1px $c-pale-lilac inset;
                    border-radius: 4px;
                    height: 100%;
                    max-height: 80px;

                    .label {
                        display: block;
                        padding: 30px 15px;
                        cursor: pointer;
                    }
                }

                &._active {

                    .payment-method-title {
                        box-shadow: 0 0 0 2px $c-dark inset;
                    }
                }
            }
        }
    }

    // Payment method content layout
    .payment-content {
        margin-top: 40px;

        .legend {
            margin-top: 15px;
        }

        .payment-method-title {
            display: none;
        }

        .payment-method-content {
            padding: 0;
        }

        .actions-toolbar {
            margin-top: 30px;
            margin-left: 0 !important;

            > .primary {
                float: none;
            }
        }

        .payment-method-billing-address {
            margin-top: 20px;

            .actions-toolbar {
                margin-top: 5px;

                > .primary {
                    float: none;
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                    flex-direction: row-reverse;

                    .action-update {
                        @extend .primary--border;
                        width: auto;
                    }

                    .action-cancel {
                        @extend .button-as-link;
                        @extend .secondary--underline;
                        width: auto;
                        margin-right: 30px;
                        text-transform: capitalize;
                        color: $c-bluey-grey;

                        &:after {
                            background-color: $c-bluey-grey;
                        }
                    }
                }
            }
        }

        .billing-address-form {
            max-width: none;
        }
    }
}

.checkout-payment-method {

    //.payment-option {
    //
    //    &._active {
    //
    //        .payment-option-title {
    //
    //            .action-toggle {
    //
    //                &:after {
    //                    content: $icon-up;
    //                }
    //            }
    //        }
    //    }
    //}

    .redirection {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $c-pale-grey;
        padding: 54px 30px;
        border-radius: 4px;

        p {
            max-width: 300px;
            margin-bottom: 0;
            margin-top: 30px;
            text-align: center;
            color: $c-bluey-grey;
        }
    }

    .payment-option-inner {
        margin: 0 0 $indent__base;
    }

    .credit-card-types {
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 0 $indent__xs 0 0;
            vertical-align: top;

            &._active {
                font-weight: $font-weight__bold;

                img {
                    filter: grayscale(0%);
                }
            }

            &._inactive {
                opacity: 0.4;
            }

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        img {
            filter: grayscale(100%); // For Webkit browsers
            transition: all 0.6s ease; // Fade to color for Chrome and Safari
        }
    }

    .ccard {

        .legend {
            @extend .abs-visually-hidden;
        }

        .month {
            width: 50%;
        }

        .year {
            width: 50%;
            padding-left: 15px;
        }

        .field.cc-number,
        .field.number {
            position: relative;

            input[type="text"] {
                padding-right: 54px;
            }

            .credit-card-types {
                position: absolute;
                right: 5px;
                top: 36px;

                .item {
                    filter: grayscale(1);
                    margin-right: 0;

                    &._active {
                        filter: grayscale(0);
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

//@include max-screen($screen__m) {
//
//    .opc-payment {
//
//        // Payment method content layout
//        .payment-content {
//
//            .payment-method-billing-address {
//
//                .actions-toolbar {
//                    margin-top: 0 !important;
//                }
//            }
//        }
//    }
//}
