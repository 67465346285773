//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: $primary__color !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: false !default;

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    margin-bottom: $indent__base;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.header {

    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;

        @media screen and (max-width: $screen__l) {
            padding-top: 0;
        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {

    > .page-title-wrapper {

        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {

    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {

    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}



.page-header,
.page-footer {

    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {

    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header {

    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {

    td {
        padding: 0;
    }
}

//
//  Promo Text Banner
//  ---------------------------------------------

.text-promo-banner {
    max-width: 1320px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9fb;
    margin: 15px auto;

    p {
        text-transform: uppercase;
        margin: 0 7px 0 0;
        color: #1c2028;
        letter-spacing: 1px;
    }

    a {
        text-decoration: underline;
        color: #858c8f;
    }

    @media screen and (max-width: $screen__m) {
        height: 100px;
        flex-direction: column;
    }
}


//
//  Desktop
//  _____________________________________________

@media screen and (min-width: $screen__l) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {

        &.panel {

            > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            //@extend .abs-add-clearfix-desktop;
            padding: $indent__l $indent__base 0;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            //@extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {

        > .page-title-wrapper {

            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: -8px auto 25px 0;

        img {
            max-height: inherit;
        }
    }
}



.cms-page-view {
    .page-main {
        max-width: 1440px;
    }

    .icon-options-block {
        margin: 140px 0;

        @media screen and (max-width: $screen__m) {
            margin: 80px 0;
        }

        .title {
            font-size: 32px;
            letter-spacing: 0.5px;
            text-align: center;
            margin-bottom: 50px;

            @media screen and (max-width: $screen__m) {
                display: none;
            }
        }

        .advantages {
            margin: 140px auto;
            max-width: 800px;

            @media screen and (max-width: $screen__m) {
                .advantage {
                    width: 100%;
                    margin: 25px 0;
                }
            }
        }
    }

    .specification-block {
        max-width: 1100px;
        margin: 0 auto;

        @media screen and (max-width: $screen__m) {
            margin: 0 20px;
        }

        .title {
            text-align: center;
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0.5px;
            margin-bottom: 50px;
        }

        .additional-attributes {
            .inner-block {
                @include lib-vendor-prefix-display(flex);
                padding: 30px 0;
                border-top: solid 1px $c-pale-lilac;

                @media screen and (max-width: $screen__m) {
                    flex-direction: column;
                }

                &:last-child {
                    border-bottom: solid 1px $c-pale-lilac;
                }

                .label {
                    max-width: 240px;
                    width: 100%;

                    @media screen and (max-width: $screen__m) {
                        margin-bottom: 20px;
                    }
                }

                .label,
                .data {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                }
            }

            .action {
                border-top: solid 1px $c-pale-lilac;
                padding-top: 27px;
                display: block;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: normal;
                text-align: center;
                position: relative;

                @include icomoon("arrow-down", "before", true, true) {
                    font-size: 14px;
                    color: $c-bluey-grey;
                }

                &:before {
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &.less {
                    &:before {
                        transform: translateX(-50%) rotate(180deg);
                    }
                }
            }
        }
    }

    .text-files-block {
        margin: 140px 0;
        @include lib-vendor-prefix-display(flex);

        @media screen and (max-width: $screen__m) {
            flex-direction: column;
        }

        p {
            margin: 0;
        }

        .inner-block {
            flex: 1;
            text-align: center;

            &:not(:last-child) {
                border-right: solid 1px #979797;
                padding-right: 35px;

                @media screen and (max-width: $screen__m) {
                    border-right: none;
                    margin-bottom: 40px;
                }

                @media screen and (max-width: 1025px) and (min-width: 768px) {
                    padding-right: 35px;
                }
            }

            .title {
                font-size: 32px;
                font-weight: normal;
                letter-spacing: 0.5px;
                margin-bottom: 50px;
                text-align: center;
                margin-top: -10px;
                line-height: 3rem;

                @media screen and (max-width: 1025px) and (min-width: 768px) {
                    line-height: 35px;
                }
            }

            .description {
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
            }

            .description,
            .icons-wrapper-block {
                max-width: 520px;
                margin: 0 auto;
                padding-bottom: 40px;
            }

            .icons-wrapper-block {
                @include lib-vendor-prefix-display(flex);
                justify-content: center;

                @media screen and (max-width: $screen__m) {
                    flex-direction: column;
                }

                .icon-block {
                    margin: 0 50px;

                    @media screen and (max-width: $screen__m) {
                        margin: 25px 50px;
                    }

                    .icon {
                        font-size: 44px;

                        @media screen and (max-width: $screen__m) {
                            font-size: 64px;
                        }
                    }

                    .text {
                        font-size: 12px;
                        letter-spacing: 0.19px;
                        line-height: normal;

                        @media screen and (max-width: $screen__m) {
                            margin-top: 10px;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .block-3-columns {
        margin: 5rem auto;

        .row {
            max-width: 1100px;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            .col {
                width: 33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 15px;

                .inner-block {
                    width: 100%;

                    > img {
                        width: 100%;
                        height: 220px;
                        object-fit: cover;
                    }
                }

                .text-block {
                    text-align: center;

                    p.title {
                        font-size: 24px;
                        letter-spacing: 1px;
                        margin: 15px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $screen__l) {
        .block-3-columns {
            margin: 2rem auto;

            .row {
                flex-direction: column;
                align-items: center;

                .col {
                    width: 100%;
                    padding: 1rem;
                    margin-bottom: 3rem;
                }
            }
        }
    }

    @media screen and (max-width: 1025px) and (min-width: 768px) {
        .block-3-columns {
            .row {
                flex-direction: row;
                align-items: baseline;

                .col {
                    width: 33%;

                    .title {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 860px) and (min-width: 769px) {
        .block-3-columns {
            .row {
                .col {
                    .inner-block {
                        .text-center {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }

    .description-image-wrapper {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        align-items: center;
        margin: 50px 0;


        @media screen and (max-width: $screen__l) {
            margin: 50px -20px 30px -20px;
            flex-direction: column-reverse;
        }

        .description-wrapper {
            max-width: 400px;
            width: 100%;
            margin-right: 90px;

            @media screen and (max-width: $screen__l) {
                margin: 0 1.6rem;
            }

            .title-wrapper{
                .title{
                    font-size: 32px;
                    letter-spacing: 0.5px;
                    color: $c-dark;
                }

                @media screen and (max-width: $screen__l) {
                    text-align: center;
                    margin-top: 25px;
                }

                @media screen and (max-width: $screen__m) {
                    padding: 0 3rem;
                }
            }

            .description {
                margin-top: 30px;
                font-size: 14px;
                line-height: 1.71;
                color: $c-dark;

                @media screen and (max-width: $screen__l) {
                    padding-bottom: 3rem;
                    text-align: center;
                    border-bottom: 1px solid #e9e9eb;
                }

                @media screen and (max-width: $screen__m) {
                    padding: 0 3rem;
                }
            }
        }

        .image {
            max-width: 780px;
            width: 100%;
        }
    }

    .information-list {
        width: 100%;
        background: $c-dark-green;

        ol {
            text-align: center;

            @media screen and (max-width: $screen__l) {
                padding: 22px 0;
            }


            li {
                display: inline-block;
                font-size: 14px;
                letter-spacing: 0.5px;
                text-align: center;
                text-transform: uppercase;
                color: $c-white;
                margin-bottom: 0;
                padding: 22px 0;

                @media screen and (max-width: $screen__l) {
                    padding: 0;
                    width: 40%;
                    text-align: left;
                    margin: 0 auto;
                }

                @media screen and (max-width: $screen__m) {
                    width: 100%;
                    padding: 0 20px 10px 20px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                &:not(:last-child) {
                    margin-right: 40px;

                    @media screen and (max-width: $screen__l) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .block-full-width {
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #f9f9fb;

        .content {
            max-width: 1440px;
            width: 100%;
            padding: 0 40px 40px;

            @media screen and (min-width: $screen__l) {
                padding: 40px;
            }

            .text {
                padding: 0 35px;
            }
        }
    }
}

// Telephone mobile/tablet || desktop

.tag-mobile-tablet {
    cursor: pointer;
}

.tag-desktop {
    cursor: default;
    text-decoration: none !important;

    &:hover {
        text-decoration: none;
        cursor: default;
    }
}