.actions-toolbar {

    > .primary,
    > .secondary {
        text-align: left;
        margin-bottom: $indent__s;

        .action {
            @extend .abs-button-responsive;
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {

    .actions-toolbar {
        text-align: left;

        > .secondary,
        > .primary {
            margin-bottom: 0;

            .action {
                margin-bottom: 0;
                width: auto;
            }
        }
    }
}
