.load.indicator {
    @include lib-loader();
    position: absolute;

    > span {
        display: none;
    }
}

.loading-mask {
    @include lib-loading-mask();
    background: rgba(255, 255, 255, 0.5);

    .loader {
        $loader-size: 38px;

        > img {
            @include lib-loading-mask();
            box-sizing: border-box;
            width: $loader-size;
            height: $loader-size;
            padding-left: $loader-size;
            background-image: url('../images/loader.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }

        > p {
            display: none;
        }
    }
}

.product-media {
    .gallery-placeholder {
        .loading-mask {
            display: none;
        }
    }
    .gallery-placeholder {
        &._block-content-loading {
            .loading-mask {
                display: block;
            }
        }
    }
}

body {

    > .loading-mask {
        z-index: $loader-overlay__z-index;
    }
}

._block-content-loading {
    position: relative;
}
