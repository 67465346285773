$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;
$product-page__max-width: 1440px !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------
.catalog-category-view {
    background: #f9f9fb;
}
.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(1.3);
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product-contact-options {
    margin: 140px 0;

    @media screen and (max-width: $screen__m) {
        margin: 80px 0;
    }

    .title {
        font-size: 32px;
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 50px;

        @media screen and (max-width: $screen__m) {
            display: none;
        }
    }

    .advantages {
        margin: 0 auto;

        .advantage {
            cursor: pointer;

            @media screen and (max-width: $screen__m) {
                width: 100%;
                margin: 25px 0;
            }
        }
    }
}

.product-lifestyle-image {
    margin-top: 132px;
    margin-left: -$new-layout-padding;
    margin-right: -$new-layout-padding;

    @media screen and (max-width: $screen__m) {
        margin-left: 0;
        margin-right: 0;
    }
}

.additional-attributes-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: $screen__m) {
        margin: 0 20px;
    }

    .title {
        text-align: center;
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 0.5px;
        margin: 50px;
    }

    .additional-attributes {
        .inner-block {
            @include lib-vendor-prefix-display(flex);
            padding: 30px 0;
            border-top: solid 1px $c-pale-lilac;

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
            }

            &:last-child {
                border-bottom: solid 1px $c-pale-lilac;
            }

            .label {
                max-width: 240px;
                width: 100%;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 20px;
                }
            }

            .label,
            .data {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.71;
                letter-spacing: normal;
            }
        }

        .action {
            border-top: solid 1px $c-pale-lilac;
            padding-top: 27px;
            display: block;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: normal;
            text-align: center;
            position: relative;

            @include icomoon("arrow-down", "before", true, true) {
                font-size: 14px;
                color: $c-bluey-grey;
            }

            &:before {
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%);
            }

            &.less {
                &:before {
                    transform: translateX(-50%) rotate(180deg);
                }
            }
        }
    }
}

.catalog-product-view {
    .appointment {
        max-width: $product-page__max-width;
        margin-top: 160px;

        @media screen and (max-width: $screen__m) {
            margin: 50px 20px 0 20px;

            .inner-block {
                .banner-block {
                    background-position: center !important;
                }
            }
        }
    }
}

.block.related {
    position: relative;
    max-width: $product-page__max-width;
    margin: 140px auto 0 auto;

    @media screen and (max-width: $screen__m) {
        margin: 95px auto 0 auto;
        padding: 0 20px;
    }

    .block-title {
        margin-bottom: 10px;
        margin-left: 62px;

        @media screen and (max-width: $screen__m) {
            margin: 0 auto;
            text-align: center;
        }

        strong {
            font-size: 22px;
            font-weight: normal;
        }
    }

    .products-grid {
        .product-item {
            width: 100%;
        }

        .owl-carousel {
            position: unset;
            display:flex;

            @media screen and (max-width: 1024px) {
                flex-direction: column;
            }

            .owl-item {
                max-width: 325px;
                margin-right: 9px;

                @media screen and (max-width: 1024px) {
                    max-width: 200px;
                    margin-right: 10px;
                    margin-left: 25px;
                }

                .product-item {
                    margin: 0;
                    padding: 0;
                    max-width: 325px;

                    @media screen and (max-width: $screen__m) {
                        max-width: 156px;
                        margin: 0;
                    }
                }

            }
        }
    }

    .owl-theme {
        .owl-stage-outer,
        .owl-stage {
            position: unset;
        }

        .owl-stage {
            display: flex;
        }

        .owl-nav {
            position: absolute;
            right: 58px;
            top: 0;

            .owl-prev {
                margin: 0 15px 0 0;
            }

            .owl-next {
                margin: 0 0 0 15px;
                border-radius: 0;
            }

            [class*='owl-']:hover,
            [class*='owl-']:focus {
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

.product-info-main {
    position: relative;

    .page-title-wrapper {

        @media screen and (max-width: $screen__m) {
            display: inline-block;
            width: 60%;
            vertical-align: top;
            margin-bottom: 20px;
        }

        .page-title {
            margin-bottom: 0;
            font-size: 2.4rem;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 1px;
        }
    }

    .stock {

        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {

        &.attribute {

            &.sku {
                color: $c-cloudy-blue;

                &:before {
                    content: "#";
                }

                .value {
                    display: inline-block;
                }

                .type {
                    display: none;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.page-product-configurable {
    .page-title-wrapper {
        @media screen and (max-width: $screen__m) {
            margin-bottom: 0;
        }
    }
}

.product-options-wrapper {

    .fieldset-product-options-inner {

        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {

            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {

            .legend {

                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {

        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {

    @media screen and (max-width: $screen__m) {
        .product-info-price {
            display: inline-block;
            vertical-align: top;
            text-align: right;
            width: 38%;
        }
    }

    .price-box {
        margin: 40px 0 47px 0;

        @media screen and (max-width: $screen__m) {
            margin: 0;
        }

        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: 1.6rem;
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                font-size: 1.6rem;
                line-height: 1.75;
            }
        }

        .price-wrapper .price {
            font-size: 1.6rem;
            font-weight: normal;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 30px;
            height: $tocart-input-size;
            text-align: center;
            width: $tocart-input-size;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            width: 100%;
        }
    }

    .product-addto-links {
        margin: 37px 0 50px 0;
        display: flex;
        justify-content: space-between;
    }
}

.product-options-bottom {
    @include lib-vendor-prefix-display(flex);
    justify-content: space-between;

    @media screen and (max-width: $screen__m) {
        flex-direction: column;
    }


    .delivery-delay {
        .label,
        .delay-block span {
            font-size: 12px;
            line-height: 1.67;
        }

        @media screen and (max-width: $screen__m) {
            margin-bottom: 40px;
            text-align: center;
        }

    }

    .box-tocart {
        width: 100%;
        max-width: 340px;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.product-info-more {
    margin-top: 54px;

    .short-description {
        padding-left: 10px;
        font-size: 14px;
        line-height: 1.71;
    }
}

.product-collapsible-info {
    margin-top: 50px;

    &__item {

        .title {
            @include lib-icon-font(
                $_icon-font-content     : $icon-down,
                $_icon-font-size        : 24px,
                $_icon-font-line-height : 1,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 69px;
            margin-bottom: 0;
            font-size: 1.8rem;
            border-top: 1px solid $c-pale-lilac;
            cursor: pointer;
            user-select: none;
        }

        .content {
            margin: 0 0 20px 0;

            h2 {
                font-size: 14px;
                margin: 0 0 10px 0;
            }

            ul {
                padding-left: 35px;
                list-style: disc;

                li {
                    line-height: normal;
                }
            }

            .grey-bg {
                background: #f2f2f2;
                padding: 20px;
                margin: 20px 0;
            }

            &.delivery-options {
                p {
                    margin-bottom: 10px;
                }
            }
        }

        &.active {

            .title {

                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &:last-of-type {
            border-bottom: 1px solid $c-pale-lilac;
        }
    }
}

.size-guide-modal.modal-popup {

    .modal-title {
        margin-bottom: 0;
    }

    .modal-content {
        padding-bottom: 30px;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {

    .product-items {

        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .catalog-product-view {

        .page-main {
            padding: 0;
        }
    }

    .product-info-main {
        padding: 35px 20px;
        margin-bottom: 20px;

        .box-tocart {

            .actions {

                .action.tocart {
                    @extend .abs-button-responsive-smaller;
                }
            }
        }

    }

    .block.related {

        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }


    .product-media__list {
        position: relative;

        .product-media__image {
            display: none;

            &:first-of-type {
                display: block;
            }
        }

        &.loader {

            .product-media__image {
                display: block;
            }
        }

        .owl-dots {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px 0;

            .owl-dot {

                span {
                    background-color: $c-black;
                    opacity: 0.5;
                    width: 6px;
                    height: 6px;
                    transition: opacity 0.24s ease, transform 0.24s ease;
                }

                &.active {

                    span {
                        background-color: $c-black;
                        opacity: 1;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}

.product-add-form {
    @extend .abs-revert-field-type-desktop;
}

//
//  Ipad
//  _____________________________________________

@media screen and (max-width: 1025px) and (min-width: 768px) {
    .product-info-main {
        padding: 35px 40px;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {

    .sidebar {

        .product-items {

            .product-item-info {

                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Product page layout
//  ---------------------------------------------

@include min-screen($screen__l) {

    .product-top {
        max-width: $product-page__max-width;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        .product-info-main {
            background: $c-pale-grey;
            width: 100%;
            max-width: 740px;
            padding: 50px 60px 60px 60px;
        }

        .product-media {
            width: 100%;
            padding-right: 30px;
            max-width: 700px;
            box-sizing: border-box;
            position: relative;

            .product-label {
                border-radius: 2px;
                background-color: $c-strawberry;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 28px;
                letter-spacing: normal;
                text-align: center;
                color: $c-white;
                position: absolute;
                top: 95px;
                left: 25px;
                z-index: 9;
                text-transform: lowercase;
            }
        }
    }
}

@include max-screen($screen__l) {
    .product-top {
        .product-media {
            width: 100%;
            max-width: 700px;
            box-sizing: border-box;
            position: relative;
            margin: 0 auto;

            .product-label {
                border-radius: 2px;
                background-color: $c-strawberry;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 28px;
                letter-spacing: normal;
                text-align: center;
                color: $c-white;
                position: absolute;
                top: 45px;
                left: 25px;
                z-index: 9;
                text-transform: lowercase;
            }
        }
    }
}

//
//  Promotion Block Inspirations Post View
//  ---------------------------------------------

.inspirations-post-view {
    .text-promo-banner {
        margin: 60px auto;

        @media screen and (max-width: $screen__m) {
            margin: 30px auto;
        }
    }
}