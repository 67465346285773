.pages {
    @include lib-pager();
    margin-top: 174px;

    .item {
        &.current {
            strong.page,
            a.page {
                background-color: $c-dark-green;
                color: $c-white;
            }
        }
    }

    strong.page,
    a.page {
        width: 50px;
        height: 50px;
        line-height: 50px;
        padding: 0;
        font-weight: normal;
        font-size: 1.4rem;
        color: $c-dark;
        background-color: $c-pale-grey;
    }

    a.page:hover {
        background-color: $c-pale-lilac;
        color: $c-dark;
    }

    .pages-item-previous,
    .pages-item-next {
        font-weight: normal;

        a {
            width: 100px;
            line-height: 50px;
            color: $c-dark;

            &:hover {
                color: $c-white;
                background-color: $c-dark-green;
            }
        }
    }

    .pages-item-previous {
        margin-right: 30px;
    }

    .pages-item-next {
        margin-left: 30px;
    }
}
