.fotorama__thumb-border {
    @extend .fotorama-print-background;
    position: absolute;
    top: 11px;
    left: -5px;
    box-sizing: border-box;
    border: solid $c-black;
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
    backface-visibility: hidden;
    z-index: 9;
    border-radius: 100%;
    border-width: 1px !important;
    margin-top: 0 !important;

    .fotorama__nav--dots & {
        display: none;
    }

    .fotorama__wrap--css3 & {
        @extend .fotorama-transition-for-slide;
    }
}
