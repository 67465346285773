// Appointment Popup
.appointment-popup {
    .modal-inner-wrap {
        width: 100%;
    }

    .modal-content {
        padding: 0;
    }
}

// hide popup content on load
.appointment .appointment-modal,
.header.content .appointment-modal {
    display: none;
}

.appointment-modal {
    @include lib-vendor-prefix-display(flex);

    .inner-block {

        &.banner-block {
            max-width: 445px;
            align-self: flex-start;

            img {
                display: block;
            }

            @media screen and (max-width: $screen__l) {
                display: none;
            }
        }

        &.banner-block-mobile {
            display: none;

            @media screen and (max-width: $screen__l) {
                display: block;
            }
        }

        &.form-block {
            flex: 1;
            padding: 10px 60px;
            box-sizing: border-box;

            @media screen and (max-width: $screen__l) {
                padding: 40px;
            }

            @media screen and (min-width: $screen__m) and (max-width: $screen__l) {
                max-width: 510px;
                margin: 0 auto;
            }

            @media screen and (max-width: $screen__m) {
                max-width: 505px;
                margin: 0 auto;
                padding: 20px;
            }
        }

        .form {
            .fieldset {
                @media screen and (max-width: $screen__l) {
                    margin: 0 0 50px;
                }

                .title {
                    font-size: 32px;
                    letter-spacing: 0.5px;
                    line-height: 38px;
                    margin: 0;

                    @media screen and (max-width: $screen__l) {
                        margin: 0 40px;
                    }
                }

                .subtitle {
                    margin: 20px 0 30px 0;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;

                }

                .title,
                .subtitle {
                    text-align: center;
                    font-weight: normal;
                }

                .field {
                    margin: 0 0 16px 0;

                    .label {
                        display: none;
                    }

                    .control {
                        width: 100%;

                        input::placeholder {
                            color: #8C96A9;
                        }

                        select {
                            option {
                                color: #8C96A9;
                            }
                        }
                    }

                    &.name,
                    &.last-name,
                    &.email,
                    &.phone {
                        width: 100%;
                        max-width: 204px;
                        display: inline-block;
                        box-sizing: border-box;

                        @media screen and (max-width: 500px) {
                            max-width: 100%;
                        }
                    }
                    &.name,
                    &.email {
                        margin-right: 16px;

                        @media screen and (max-width: 500px) {
                            margin-right: 0;
                        }
                    }
                }

                .control {
                    width: 100%;
                    max-width: 204px;
                    display: inline-block;
                    box-sizing: border-box;
                    margin: 0 0 16px 0;

                    @media screen and (max-width: 500px) {
                        max-width: 100%;
                    }

                    &.preferred-branch {
                        margin-left: 16px;

                        @media screen and (max-width: 500px) {
                            margin-left: 0;
                        }
                    }
                }

                .captcha {
                    @include lib-vendor-prefix-display(flex);
                    align-items: center;

                    @media screen and (max-width: 500px) {
                        flex-direction: column;
                    }

                    .text {
                        max-width: 48%;

                        @media screen and (max-width: 500px) {
                            max-width: 100%;
                        }
                    }

                    .img {
                        @include lib-vendor-prefix-display(flex);
                        align-items: center;

                        img {
                            max-height: 50px;
                            margin-left: 10px;
                        }

                        a {
                            @extend .button-as-link;
                            @extend .secondary--underline;
                            margin-left: 10px;
                        }
                    }
                }

                .required {
                    input,
                    select {
                        border-left: solid 3px rgba(244,40,55, .6);

                        &:focus {
                            border-left: none;
                        }
                    }
                }
            }

            .actions-toolbar {
                text-align: center;

                > .primary,
                > .secondary,
                > .primary:last-child,
                > .secondary:last-child {
                    text-align: center;
                }

                .action {
                    max-width: 170px;
                    margin: 0 auto;

                    @media screen and (max-width: 500px) {
                        max-width: 100%;
                        width: 100%;
                        margin: 10px auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen__l) {

    .appointment-modal {
        flex-direction: column;
    }
}
