//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width           : 600px !default;
$checkout-shipping-address__margin-top          : 28px !default;

$checkout-shipping-item-icon__color             : $color-white !default;
$checkout-shipping-item-icon__content           : $icon-checkmark !default;

$checkout-shipping-item__border                 : 2px solid transparent !default;
$checkout-shipping-item__line-height            : 30px !default;
$checkout-shipping-item__margin                 : 0 0 $indent__base !default;
$checkout-shipping-item__padding                : $indent__base ($indent__l + 5px) $indent__base $indent__base !default;
$checkout-shipping-item__transition             : 0.3s border-color !default;
$checkout-shipping-item__width                  : 100% / 3 !default;
$checkout-shipping-item-tablet__width           : 100% / 2 !default;
$checkout-shipping-item-mobile__width           : 100% !default;
$checkout-shipping-item__active__border-color   : $color-orange-red1 !default;
$checkout-shipping-item-icon__background-color  : $checkout-shipping-item__active__border-color !default;

$checkout-shipping-item-icon__selected__height  : 27px !default;
$checkout-shipping-item-icon__selected__width   : 29px !default;

$checkout-shipping-item-mobile__padding         : 0 0 15px !default;
$checkout-shipping-item-mobile__margin          : $checkout-shipping-item-mobile__padding !default;
$checkout-shipping-item-mobile__active__padding : 15px ($indent__l + 5px) 15px 18px !default;

$checkout-shipping-item-before__border-color    : $color-gray80 !default;
$checkout-shipping-item-before__height          : calc(100% - 20px) !default;

$checkout-shipping-method__border               : $checkout-step-title__border !default;
$checkout-shipping-method__padding              : $indent__base !default;

//
//  Common
//  _____________________________________________

.checkout-index-index {
    .modal-inner-wrap {
        .action-close {
            &:before {
                color: $c-dark;
            }
        }
    }
}

.opc-wrapper {

    .form-login {

        .actions-toolbar {

            .primary {

                button {
                    @extend .primary--full;
                }
            }

            .secondary {

                .action {
                    @extend .grey;
                    margin-top: 0;
                }
            }
        }

        .hidden-fields {
            margin-bottom: 50px;
        }
    }

    //
    //  Shipping Address
    //  ---------------------------------------------

    .form-login,
    .form-shipping-address {
        @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        margin-bottom: $indent__base;

        .fieldset {

            .note {
                font-size: 1.4rem;
                margin-top: 14px;
                color: $c-bluey-grey;

                &:before {
                    content: none;
                }
            }
        }
    }

    .shipping-address-items {
        font-size: 0;
        width: 100%;
        margin-top: 25px;
    }

    .shipping-address-item {
        @extend .abs-add-box-sizing;
        display: block;
        width: 100%;
        padding: 20px 20px 20px 50px;
        margin-bottom: 10px;
        border: 1px solid $c-pale-lilac;
        border-radius: 6px;
        font-size: $font-size__base;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;
        color: $c-bluey-grey;
        cursor: pointer;
        line-height: 1.5;

        &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 19px;
            left: 20px;
            background-image: url('../images/forms/radio.svg');
            background-size: 20px 20px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        span {
            display: block;
        }

        .name {
            font-weight: 700;
            margin-bottom: 10px;
        }

        a {
            color: $c-bluey-grey;
        }

        &.selected-item {
            color: $c-dark;
            border-color: $c-dark;
            cursor: default;
            pointer-events: none;

            &:before {
                background-image: url('../images/forms/radio-selected.svg');
            }

            a {
                color: $c-dark;
                pointer-events: all;
            }
        }
    }

    .field {

        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        @extend .button-as-link;
        @extend .grey;
        margin-top: 20px;
        text-transform: capitalize;
        color: $c-bluey-grey;

        &:after {
            background-color: $c-bluey-grey;
        }
    }
}

//
//  Shipping Methods
//  ---------------------------------------------

.checkout-shipping-method {

    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping {

    .actions-toolbar {

        .action {

            &.primary {
                @extend .primary--full;
                @extend .primary--full-blue;
            }
        }
    }
}

.table-checkout-shipping-method {
    margin: 25px 0 35px 0;
    color: $c-bluey-grey;

    ul {
        @extend .abs-reset-list;

        li {
            margin-bottom: 10px;
        }
    }

    .row {
        display: flex;
        align-items: center;

        .input {
            margin-right: 15px;
        }
    }

    p {
        margin-bottom: 0;
    }

    .price {
        font-weight: 700;
        color: $c-dark;
        margin-right: 7px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .opc-wrapper {

        .form-login {

            .actions-toolbar {
                margin-top: 40px;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .secondary {
                    margin-top: 15px;
                }
            }
        }

        .new-address-popup {
            text-align: center;
        }
    }
}
