.blog-page {

    @media screen and (max-width: $screen__m)  {
        .page-main {
            padding-left: unset;
            padding-right: unset;
        }
    }

    .page-title-wrapper {
        text-align: center;
        margin-bottom: 45px;

        @media screen and (max-width: $screen__m) {
            margin-bottom: 30px;
        }

        .page-title {
            font-size: 60px;
            font-weight: normal;
            letter-spacing: 0.25px;
            color: $c-dark;
            margin: 80px 0 0 0;

            @media screen and (max-width: $screen__m) {
                line-height: 1.21 !important;
                letter-spacing: 0.5px !important;
                font-size: 28px !important;
                padding-top: 30px;
            }
        }
    }

    .subtitle-blog {
        text-align: center;
        margin-bottom: 84px;

        @media screen and (max-width: $screen__m) {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 30px;
            color: #1c2028;
            line-height: 1.71;
            font-size: 14px;
            font-weight: normal;
        }

        p {
            font-size: 16px;
            font-weight: 500;

            @media screen and (max-width: $screen__m) {
                color: #1c2028;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    .post-list-wrapper {
        .post-list {

            .post-holder{
                &:not(:last-child) {
                    margin-bottom: 140px;

                    @media screen and (max-width: $screen__m) {
                        margin-bottom: 60px;
                    }
                }
            }

            .post-header {
                .post-title {
                    text-align: center;
                    margin: 0 0 42px 0;
                    font-size: 32px;
                    font-weight: normal;
                    letter-spacing: 0.5px;
                    line-height: 35px;

                    @media screen and (max-width: $screen__m) {
                        margin: 0 0 20px 0;
                        font-size: 24px;
                        font-weight: normal;
                        line-height: 1.17;
                        letter-spacing: 0.5px;
                        color: #1c2028;
                    }

                    @media screen and (max-width: 1025px) and (min-width: 768px) {
                        font-size: 24px;
                        line-height: 35px;
                    }
                }

                .post-info {
                    text-align: center;
                    margin-bottom: 52px;
                    font-size: 14px;
                    color: #9b9b9b;

                    @media screen and (max-width: $screen__m) {
                        margin-bottom: 30px;
                    }

                    a {
                        color: #9b9b9b;
                    }
                }
            }

            .post-content {
                .post-ftimg-hld {
                    margin-bottom: 50px;
                }

                .post-text-hld {
                    max-width: 80%;
                    margin: 0 auto;

                    p {
                        font-size: 14px;
                        line-height: 1.71;
                        letter-spacing: normal;
                    }
                }

                .post-read-more-block {
                    text-align: center;
                    margin-top: 52px;
                }

            }
        }
    }

    .toolbar-blog-posts .pages {
        text-align: center;
        margin: 0 auto;
        
        @media screen and (max-width: $screen__s) {
            .item.pages-item-previous {
                margin-right: 10px;
                width: auto;
            }
            .item.pages-item-next {
                margin-left: 10px;
                width: auto;
            }

            .previous, .next {
                width: auto;
            }

            strong.page, a.page {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
        }
    }

    .sidebar {
        .widget {
            margin-bottom: 56px;

            &.block-categories {
                margin-bottom: 56px;
            }

            .block-title {
                font-size: 20px;
                font-weight: normal;
                letter-spacing: 0.5px;
                color: $c-dark;

                strong {
                    font-weight: normal;
                }
            }

            .accordion {
                li {
                    font-size: 14px;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: $c-dark;
                }
            }

            &.blog-search {
                @media screen and (max-width: $screen__m) {
                    margin-top: 30px;
                    float: none;
                    width: auto;
                    padding-right: 20px;
                }

                @media screen and (max-width: $screen__s) {
                    padding-right: 0;
                }

                .form {
                    position: relative;

                    .label {
                        display: none;
                    }

                    .control {

                        .input-text {
                            border-radius: 3px;
                            border: solid 1px #e9e9eb;
                            background-color: $c-white;
                            box-shadow: none;
                            padding: 10px 12px;

                            &::placeholder {
                                color: $c-dark;
                            }
                        }
                    }
                    .action.search {
                        font-size: 0;
                        padding: 0;
                        margin: 0;
                        background: none;
                        border: none;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 3px;
                        z-index: 1;

                        @media screen and (max-width: $screen__m) {
                            padding: 10px !important;
                        }

                        @include icomoon("search", "before", true, true) {
                            font-size: 18px;
                            color: $c-dark;
                        }
                    }
                }
            }

            &.block-recent-posts {
                .items {
                    list-style: unset;
                    list-style-position: inside;
                }
            }
        }
    }
}

.blog-widget-recent,
.related-posts {
    max-width: 1100px;
    margin: 140px auto 0 auto;

    @media screen and (max-width: $screen__m) {
        margin-top: 110px;
    }

    .title {
        text-align: center;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: 0.5px;
        display: none;

        @media screen and (max-width: $screen__m) {
            display: block;
        }
    }

    .post-list {
        @include lib-vendor-prefix-display(flex);
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;

        @media screen and (max-width: 1045px) and (min-width: 768px) {
            flex-wrap: nowrap;
        }

        .post-holder {
            max-width: 320px;

            @media screen and (max-width: $screen__m) {
                margin: 0 auto 40px auto;
            }

            @media screen and (max-width: 1025px) and (min-width: 768px) {
                margin: 5px;
            }

            .post-content {
                .post-description {
                    @media screen and (max-width: $screen__m) {
                        text-align: center;
                    }

                    @media screen and (max-width: 1025px) and (min-width: 768px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    .post-posed-date {
                        padding-top: 15px;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: rgba(140, 150, 169, 0.6);

                        @media screen and (max-width: $screen__m) {
                            text-align: center;
                        }
                    }

                    .post-title-holder {
                        .post-title {
                            font-size: 24px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: 1px;
                            color: $c-dark;
                            margin-top: 10px;

                            @media screen and (max-width: $screen__m) {
                                text-align: center;
                            }

                            @media screen and (max-width: 1025px) and (min-width: 768px) {
                                text-align: center;
                            }
                        }
                    }

                    .post-info {
                        display: none;
                    }

                    .post-text-hld {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: normal;
                        color: $c-dark;
                        margin-top: 22px;

                        @media screen and (max-width: $screen__m) {
                            text-align: left;

                            p:last-child {
                                margin-bottom: 7px;
                            }
                        }
                    }

                    .post-read-more {
                        font-size: 12px;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 1.5px;
                        text-align: center;
                        color: $c-dark;
                        margin-top: 18px;
                        display: inline-block;
                        position: relative;

                        @media screen and (max-width: $screen__m) {
                            font-size: 12px;
                            font-weight: 500;
                            padding: 0 5px 3px 5px;
                        }

                        &:after {
                            content: '';
                            width: 34px;
                            height: 1px;
                            background: $c-dark;
                            position: absolute;
                            right: -54px;
                            top: 50%;
                            transform: translateY(-50%);

                            @media screen and (max-width: $screen__m) {
                                content: '';
                                width: 100%;
                                height: 1px;
                                background: $c-dark;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                top: unset;
                                right: unset;
                            }

                            @media screen and (max-width: 1025px) and (min-width: 768px) {
                                content: '';
                                width: 100%;
                                height: 1px;
                                background: $c-dark;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                top: unset;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

// POST Page
.blog-post-view {
    @media screen and (max-width: $screen__m)  {
        .page-main {
            padding-left: unset;
            padding-right: unset;
        }
    }

    .page-title-wrapper {
        margin: 0 0 72px 0;

        @media screen and (max-width: $screen__m) {
            padding-left: 20px;
            padding-right: 20px;
            margin: 0 0 30px 0;
        }

        .page-title {
            font-size: 32px;
            letter-spacing: 0.5px;
            line-height: 38px;
            margin-top: 60px;
        }

    }

    .post-view {
        width: 90%;
        margin: 0 auto;

        @media screen and (max-width: $screen__m) {
            width: 100%;
        }

        .post-header {
            text-align: center;

            .post-info {
                color: #9B9B9B;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                margin-bottom: 60px;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 30px;
                }

                a {
                    color: #9B9B9B;
                }
            }
        }

        .post-content {
            .post-text-hld {
                width: 59%;
                margin: 80px auto;

                @media screen and (max-width: $screen__m) {
                    width: 85%;
                    margin: 25px auto;

                    h3 {
                        margin: 4rem 0;
                        letter-spacing: 1px;
                        color: #1c2028;
                        font-size: 24px;
                        font-weight: normal;
                    }
                }
            }

        }

        .post-bottom {
            border-top: solid 1px #E9E9EB;

            .post-nextprev-hld {
                padding-top: 45px;

                .nextprev-link {
                    color: #1C2028;
                    font-size: 20px;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                    text-transform: lowercase;

                    &.prev-link {
                        float: left;
                    }

                    &.next-link {
                        float: right;
                    }
                }
            }
        }
    }
}
