$layout-column-main__sidebar-offset       : 70px !default;
$layout-column__additional-sidebar-offset : $layout-column-main__sidebar-offset !default;
$layout-column-main__sidebar-additional-offset: 60px !default;
$new-sidebar-width: 190px;
$new-sidebar-additional-width: 240px;
$new-layout-padding: 40px;
$layout__max-width: 1440px;

.columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include lib-clearfix();

    .column.main {
        @extend .abs-add-box-sizing;
        @include lib-css(padding-bottom, $indent__xl);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-order(1);
        width: 100%;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(1);
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(2);
    }
}

body {
    margin-top: 120px;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .page-main {
        width: 100%;
        box-sizing: border-box;
    }

    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }

    .columns {

        .column.main {
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        //max-width: $layout__max-width;
        padding-left: $new-layout-padding;
        padding-right: $new-layout-padding;
        width: auto;
    }

    .catalog-category-view,
    .cms-page-view,
    .blog-index-index,
    .blog-page,
    .cms-noroute-index {
        .breadcrumbs,
        .page-main {
            max-width: $layout__max-width;
        }
    }

    .cms-page-view {
        .page-main {
            max-width: 700px;
            margin: 0 auto;
        }
    }

    .page-main {
        width: 100%;
        box-sizing: border-box;

        // These flex properties should be combined to `flex: 1 0 auto`
        // sass-lint:disable no-css-comments
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        // sass-lint:enable  no-css-comments

        .ie9 & {
            width: auto;
        }
    }

    .column.main {
        @extend .abs-add-box-sizing-desktop;
        width: 100%;
        min-height: 300px;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        max-width: $new-sidebar-width + $layout-column-main__sidebar-offset;
        padding-right: $layout-column-main__sidebar-offset;
    }

    // TODO: Test additonal sidebar on twocolumns right layout
    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        clear: right;
        max-width: $new-sidebar-width + $layout-column-main__sidebar-offset;
        padding-right: $layout-column__additional-sidebar-offset;
    }

    .page-layout-2columns-left {

        .column.main {
            @include lib-vendor-prefix-flex-basis(calc(100% - #{$new-sidebar-width + $layout-column-main__sidebar-offset}));
            width: calc(100% - #{$new-sidebar-width + $layout-column-main__sidebar-offset});
        }
    }

    .page-layout-2columns-right {

        .columns {
            justify-content: flex-end;
        }

        .column.main {
            @include lib-vendor-prefix-flex-basis(calc(100% - #{$new-sidebar-additional-width + $layout-column-main__sidebar-additional-offset}));
            width: calc(100% - #{$new-sidebar-additional-width + $layout-column-main__sidebar-additional-offset});
            order: -1;
        }

        .sidebar-main {
            margin-left: $layout-column-main__sidebar-offset;
            padding-right: 0;
        }

        .sidebar-additional {
            margin-left: $layout-column-main__sidebar-additional-offset;
            padding-right: 0;
            max-width: $new-sidebar-additional-width;
        }
    }

    .page-layout-2columns-left {

        .sidebar-additional {
            padding-left: 0;
            max-width: $new-sidebar-width + $layout-column-main__sidebar-offset;
        }
    }

    .page-layout-3columns {

        .column.main {
            @include lib-vendor-prefix-flex-basis(calc(100% - #{($new-sidebar-width + $layout-column-main__sidebar-offset) * 2}));
            width: calc(100% - #{($new-sidebar-width + $layout-column-main__sidebar-offset) * 2});
        }

        .sidebar-additional {
            padding-right: 0;
            padding-left: $layout-column-main__sidebar-offset;
        }
    }

    .panel.header {
        padding: 10px 20px;
    }
}


@include max-screen($screen__l) {
    body {
        margin-top: 60px;
    }

    .header.content {
        padding: 0 20px;
    }
}
