
.opc-wrapper {

    .discount-code {
        padding-top: 30px;
        margin-top: 40px;
        border-top: 1px solid $c-pale-lilac;

        .payment-option-title .label {
            display: block;
            margin-bottom: 5px;
        }

        .form-discount {
            display: flex;
            align-items: center;

            .field {

                .label {
                    @extend .abs-visually-hidden;
                }
            }

            .payment-option-inner {
                width: 100%;
                margin-bottom: 0;
                margin-right: 20px;
            }

            .action-apply {
                @extend .primary--border;
                white-space: nowrap;
            }
        }
    }
}

@include max-screen($screen__m) {

    .opc-wrapper {

        .discount-code {

            .actions-toolbar {
                margin-top: 0 !important;
            }
        }
    }
}
