.product-attachments-wrapper {
    margin: 140px 0;
    @include lib-vendor-prefix-display(flex);

    @media screen and (max-width: $screen__m) {
        flex-direction: column;
        padding: 0 20px;
    }

    p {
        margin: 0;
    }

    .inner-block {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
            border-right: solid 1px #979797;

            @media screen and (max-width: $screen__m) {
                border-right: none;
                margin-bottom: 40px;
            }
        }

        .title {
            font-size: 32px;
            font-weight: normal;
            letter-spacing: 0.5px;
            margin-bottom: 50px;
            text-align: center;
            margin-top: -10px;
        }

        .description {
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
        }

        .description,
        .icons-wrapper-block {
            max-width: 520px;
            margin: 0 auto;
            padding-bottom: 40px;
        }

        .icons-wrapper-block {
            @include lib-vendor-prefix-display(flex);

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
            }

            .icon-block {
                margin: 0 50px;

                @media screen and (max-width: $screen__m) {
                    margin: 25px 50px;
                }

                .icon {
                    max-width: 40px;
                    display: block;
                    margin: 0 auto 15px auto;
                }

                .text {
                    font-size: 12px;
                    letter-spacing: 0.19px;
                    line-height: normal;

                    @media screen and (max-width: $screen__m) {
                        margin-top: 10px;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
