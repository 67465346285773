.customer-account-logoutsuccess {
    .page-main {
        max-width: $layout__max-width;
        margin: 0 auto;
        text-align: center;

        .page-title-wrapper {
            margin: 117px 0 33px 0;

            .page-title {
                margin: 0;
                padding: 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            color: $c_grey02;
        }
    }
}
