@mixin material-cost ($symbols, $inner-selector: "span", $font-size: "24px", $empty-color: "#bfc9cd", $fill-color: $c-dark, $line-height: "50px") {

    & {
        line-height: #{$line-height};
        position: relative;
    }

    &:after,
    #{$inner-selector} {
        font-size: #{$font-size};
        color: #{$empty-color};
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
    }

    &:after {
        content: "#{$symbols}";
    }

    #{$inner-selector} {
        color: #{$fill-color};
        z-index: 9;
        text-align: left;
    }
}
