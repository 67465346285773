//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error !default;

.field {

    .control {

        &._with-tooltip {
            @extend .abs-field-tooltip;

            input {
                width: 100%;
                padding-right: 40px;
            }

            .field-tooltip {

                .field-tooltip-action {
                    line-height: 46px;
                    padding: 0 10px;
                }

                > .label {
                    display: none;
                }
            }
        }
    }

    &.choice {

        .field-tooltip {
            display: inline-block;
            position: relative;
            top: -3px;
        }

        .field-tooltip-action {
            @include lib-css(line-height, $checkout-tooltip-icon__font-size);
        }
    }

    &._error {
        .control {
            input,
            select,
            textarea {
                @include lib-css(border-color, $checkout-field-validation__border-color);
            }
        }
    }

    &[name="shippingAddress.street.0"] {

        > .label {
            display: none;
        }
    }
}

#shipping-new-address-form {

    .field.street {

        .control .field > .label {
            display: none;
        }
    }

    [name] {
        box-sizing: border-box;
    }

    [name="shippingAddress.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }

    [name="shippingAddress.lastname"] {
        width: 50%;
        float: right;
    }

    [name="shippingAddress.company"] {
        clear: both;
    }

    [name="shippingAddress.region_id"] {
        width: 33%;
        float: left;
        padding-right: 15px;
    }

    [name="shippingAddress.country_id"] {
        width: 33%;
        float: left;
        padding-right: 15px;
    }

    [name="shippingAddress.postcode"] {
        width: 33%;
        float: right;
    }

    [name="shippingAddress.telephone"] {
        clear: both;
    }
}

// Shipping form
.billing-address-form {

    .field.street .field > .label {
        display: none;
    }

    [name] {
        box-sizing: border-box;
    }

    [name="billingAddresspayflowpro.firstname"],
    [name="billingAddresscheckmo.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }

    [name="billingAddresspayflowpro.lastname"],
    [name="billingAddresscheckmo.lastname"] {
        width: 50%;
        float: right;
    }

    [name="billingAddresspayflowpro.company"],
    [name="billingAddresscheckmo.company"] {
        clear: both;
    }

    [name="billingAddresspayflowpro.city"],
    [name="billingAddresscheckmo.city"] {
        width: 66%;
        float: left;
        padding-right: 15px;
    }

    [name="billingAddresspayflowpro.postcode"],
    [name="billingAddresscheckmo.postcode"] {
        width: 33%;
        float: right;
    }

    [name="billingAddresspayflowpro.region_id"],
    [name="billingAddresscheckmo.region_id"] {
        width: 50%;
        float: left;
        padding-right: 15px;
    }

    [name="billingAddresspayflowpro.country_id"],
    [name="billingAddresscheckmo.country_id"] {
        width: 50%;
        float: right;
    }

    [name="billingAddresspayflowpro.telephone"],
    [name="billingAddresscheckmo.telephone"] {
        clear: both;
    }
}

