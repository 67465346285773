//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    margin-top: 0;

    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
}

.checkout-header {
    width: 100%;

    &-container {
        max-width: 1100px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 22px;
        box-sizing: border-box;

        @media screen and (max-width: $screen__m) {
            justify-content: space-between;
        }
    }

    .logo {
        float: none;
        display: block;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .secure {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            position: relative;
            top: 2px;
        }
    }
}

// Footer
.checkout-footer {
    padding: 0 30px;
    width: 100%;
    box-sizing: border-box;

    &-container {
        max-width: 1100px;
        margin: 0 auto;
    }

    ul {
        display: flex;
        justify-content: space-around;
        padding: 20px 0;
        margin-bottom: 0;
        list-style-type: none;
        color: $c-bluey-grey;
        border-top: 1px solid $c-pale-lilac;

        a {
            color: $c-bluey-grey;
            font-size: 12px;

            &:hover {
                color: $c-dark;
            }
        }
    }
}

.opc-wrapper {
    box-sizing: border-box;
    width: 48%;
    max-width: 540px;
    margin-top: 50px;
    padding-right: 15px;
    float: left;


    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }

    .fieldset > .field {
        @include lib-form-field-type-revert($_type: block);

        &.required,
        &._required {

            .label {
                &:after {
                    content: none;
                }
            }
        }
    }
}

.checkout-index-index {

    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }

    .column:not(.sidebar-additional) form .actions-toolbar,
    .column:not(.sidebar-main) form .actions-toolbar {
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:before,
        &:after {
            display: none;
        }
    }
}

@include max-screen($screen__m) {

    .checkout-container {
        padding-right: 0;
        padding-left: 0;
    }

    .checkout-header {

        .secure {

            span {
                display: none;
            }
        }
    }

    .opc-wrapper {
        width: 100%;
        max-width: none;
        padding-right: 0;

        .step-title {
            text-align: center;
        }
    }

    .checkout-index-index {

        .column:not(.sidebar-additional) form .actions-toolbar,
        .column:not(.sidebar-main) form .actions-toolbar {

            .action.continue {
                margin-top: 60px;
            }

            > .primary {
                width: 100%;
            }
        }
    }
}
// Success page
// ---------------------------------------------------------------------------
.checkout-onepage-success {

    .print {
        display: none;
    }

    .page-main {
        max-width: 1100px;
    }

    .columns {
        &:before,
        &:after {
            content: none;
        }

        .column.main {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: $screen__m) {
                flex-direction: column-reverse;
            }
        }
    }

    .page-title-wrapper {
        text-align: center;
        margin-top: 94px;

        .page-title {
            font-size: 32px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-bottom: 20px;

            @media screen and (max-width: $screen__m) {
                max-width: 80%;
                padding: 40px 0;
                font-size: 28px;
                line-height: 1.21;
                text-align: center;
                margin: 0 auto;

                span {
                    display: block;
                }
            }
        }

        > p {
            margin-bottom: 110px;
            font-size: 16px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0.67px;
            text-align: center;

            .order-number strong {
                font-weight: normal;
            }

            @media screen and (max-width: $screen__m) {
                color: $c-bluey-grey;
                margin-bottom: 40px;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;

                .order-number {
                    color: $c-bluey-grey;
                }
            }
        }
    }

    .checkout-success {
        width: 100%;
        max-width: 580px;

        &__top {
            text-align: center;
            letter-spacing: 0.5px;

            p {
                font-size: 1.6rem;
            }
        }

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__order-information {
            width: 100%;
            max-width: 580px;
            padding-right: 30px;

            @media screen and (max-width: $screen__m) {
                padding-right: 0;

            }

            .details {
                h2 {
                    margin-top: 0;
                    font-size: 26px;
                    font-weight: normal;
                    line-height: normal;
                    letter-spacing: 1px;

                    @media screen and (max-width: $screen__m) {
                        text-align: center;
                    }
                }

                &__section {
                    display: flex;
                    padding-bottom: 60px;
                    margin-bottom: 46px;
                    border-bottom: 1px solid $c-pale-lilac;

                    @media screen and (max-width: $screen__m) {
                        flex-direction: column;
                        text-align: center;
                        padding-bottom: 38px;
                        margin-bottom: 31px;
                    }

                    .col {
                        @media screen and (max-width: $screen__m) {
                            &:nth-child(2) {
                                margin-top: 28px;
                            }
                        }

                        h3 {
                            font-size: 20px;
                            font-weight: normal;
                            line-height: normal;
                            letter-spacing: 0.5px;
                        }

                        &:first-of-type {
                            margin-right: 100px;

                            @media screen and (max-width: $screen__m) {
                                margin-right: 0;
                            }
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                    &.items {
                        padding-bottom: 46px;

                        @media screen and (max-width: $screen__m) {
                            margin-bottom: 50px;
                            text-align: left;
                        }

                        .col {
                            width: 100%;
                            margin: 0;

                            .product {
                                @include lib-vendor-prefix-display(flex);
                            }

                            .product-item {
                                max-width: 100%;
                                padding-bottom: 0;

                                &:not(:first-child) {
                                    border-top: none;
                                }
                            }

                            .product-image-container {
                                margin-right: 20px;
                            }

                            .product-item-name-container {
                                flex: 1;
                                margin-top: 20px;

                                @media screen and (max-width: $screen__m) {
                                    margin-top: 0;
                                }

                                .product-item-name-block {
                                    display: table-cell;
                                    width: 100%;

                                    .product-item-name {
                                        margin: 0;
                                        padding: 0;
                                    }
                                }

                                .product-item-options,
                                .details-qty {
                                    .label {
                                        font-weight: normal;
                                        font-size: 14px;
                                    }
                                }

                                .product-item-inner,
                                .product-item-options,
                                .details-qty {
                                    padding-bottom: 7px;
                                }
                            }

                            .product-item-price {
                                margin-top: 20px;

                                @media screen and (max-width: $screen__m) {
                                    margin-top: 0;
                                }

                                .subtotal {
                                    display: table-cell;

                                    .price {
                                        font-weight: normal;
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }

                    .payment-method {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-right: 10px;
                        }
                    }
                }

                address span {
                    display: block;
                }
            }

            .actions-toolbar {
                .action {
                    box-sizing: border-box;

                    @media screen and (max-width: $screen__m) {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }

        &__registration {
            max-width: 335px;
            width: 100%;
            padding: 50px 40px;
            background-color: $c-pale-grey;
            border: 1px solid $c-pale-lilac;
            border-radius: 4px;
            text-align: center;
            box-sizing: border-box;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 64px;
            }

            .message {
                margin-bottom: 20px;
            }

            .email {
                margin-bottom: 0;

                .medium {
                    font-weight: normal;
                }
            }

            .action {
                margin-top: 40px;
                display: inline-block;
                padding: 16px 0;
                width: 100%;
            }
        }
    }
}
