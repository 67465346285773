//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: $color-orange-red1 !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: 500 !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $c-pale-grey !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;
$layout__max-width__pages: 1024px !default;

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword,
.sales-guest-form,
.aw_rma-guest-index,
.aw_rma-guest-createrequest,
.contact-index-index {

    .page-main {
        padding-top: 73px;
        box-sizing: border-box;
        max-width: $layout__max-width__pages;

        @media screen and (max-width: $screen__m) {
            padding-top: 34px;
        }
    }

    .s_craftsmanship {
        display: none;
    }

    .login-container {
        display: flex;
        padding-bottom: 40px;

        > .block {
            width: 50%;
        }
    }

    .page-title-wrapper {
        text-align: center;

        .page-title {
            font-size: 26px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            margin-bottom: 57px;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 31px;
            }
        }
    }

    .block-title {

        p {
            font-size: 20px;
            font-weight: normal;
            letter-spacing: 0.5px;
            margin-bottom: 18px;

            @media screen and (max-width: $screen__m) {
                text-align: center;
                margin-bottom: 20px;
            }

            &.subtitle {
                font-size: 14px;
                font-weight: normal;
                text-align: left;
                line-height: normal;
                margin-bottom: 38px;

                @media screen and (max-width: $screen__m) {
                    text-align: center;
                }
            }
        }
    }

    .block-customer-login {
        padding-right: 73px;
        position: relative;
    }

    .block-new-customer {
        padding-left: 74px;

        .actions-toolbar {

            .primary {
                width: 100%;
                display: block;
                float: none;
                margin-top: 0;
                box-sizing: border-box;
            }
        }
    }

    .login {

        .field:not(.choice) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;

            .label,
            .control {
                width: 100%;
            }

            .label {
                text-align: left;
                margin-bottom: 6px;

                &:after {
                    display: none;
                }
            }
        }

        .field.choice {
            text-align: left;

            &:before {
                display: none;
            }

            .tooltip {
                font-weight: normal;
                font-size: 13px;
                text-decoration: underline;
            }
        }

        .field {
            margin-top: 20px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .actions-toolbar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 0 !important;
            margin-top: 32px;

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
                justify-content: center;
            }

            .primary {
                max-width: 163px;
                width: 100%;

                @media screen and (max-width: $screen__m) {
                    max-width: 100%;
                }

                > .primary {
                    padding: 17px 0;
                }
            }

            .secondary {
                margin-top: 16px;

                @media screen and (max-width: $screen__m) {
                    margin-top: 5px;
                    text-align: center;
                }

                .action {
                    @extend .underline__dark-blue;
                }
            }
        }
    }

    .password.forget,
    .password.reset,
    .form-orders-search,
    .form.contact {
        max-width: 520px;
        margin: 0 auto;

        .field.note {
            margin-bottom: 40px;
            font-size: 14px;
            font-weight: normal;
            text-align: center;
        }

        .captcha {

            .captcha-image {
                display: flex;
                align-items: flex-start;
                margin-top: 20px;
            }

            .action.reload {
                margin-top: 0;
                margin-left: 16px;
            }
        }

        #password-strength-meter-container {
            margin-top: 10px;
        }

        .actions-toolbar {
            margin-left: 0;
            flex-direction: row-reverse;

            .secondary {

                .action.back {
                    margin-top: 0;

                    &:before {
                        content: url(../images/icons/link-arrow-black.svg);
                        margin-right: 10px;
                        vertical-align: sub;
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }

    .create.account {
        max-width: 520px;
        margin: 0 auto;

        legend {
            font-size: 26px;
            text-align: center;
            float: none;
            margin-bottom: 10px;
        }

        #password-strength-meter-container {
            margin-top: 10px;
        }

        .actions-toolbar {
            margin-left: 0;
            margin-top: 30px;
            flex-direction: row-reverse;

            .secondary {
                text-align: center;
                display: inline-block;
                margin: 18px auto;
                width: 100%;

                .action.back {
                    margin-top: 0;
                }
            }
        }
    }

    .control.captcha-image {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        button {
            margin: 0;
            vertical-align: top;
        }
    }

    @media screen and (max-width: $screen__l) {

        .block-customer-login {
            padding-right: 5%;
        }

        .block-new-customer {
            padding-left: 5%;
        }
    }

    @media screen and (max-width: $screen__m) {
        .login-container {
            flex-direction: column;
            align-items: center;

            > .block {
                width: 100%;
            }
        }

        .block-customer-login {
            padding-right: 0;
            padding-bottom: 46px;

            &:after {
                content: none;
            }
        }

        .block-new-customer {
            padding-left: 0;
        }
    }

    .form-create-account,
    .password.forget{
        > .fieldset {
            margin: 0;

            > .legend {
                display: none;
            }
        }

        .fieldset > .field:not(.choice) .label {
            text-align: left;
            padding-bottom: 7px;
            width: 100%;

            &:after {
                display: none;
            }
        }

        .field:not(.choice) {
            max-width: 540px;
            @include lib-vendor-prefix-display(flex);
            flex-direction: column;

            > .control {
                width: 100%;
            }

            &.group-name {
                .field-name-firstname {
                    margin: 0 0 29px;

                }
            }
        }

        .field.choice {
            &.persistent {
                display: none;
            }

            &:before {
                display: none;
            }
        }

        .field.password-info {
            margin-bottom: 40px;
        }

        .actions-toolbar {
            .primary {
                max-width: 263px;
                margin: 0 auto;

                @media screen and (max-width: $screen__m) {
                    max-width: 100%;
                }
            }

            .secondary {
                margin: 18px auto;
                text-align: center;
                .action {
                    margin: 0;
                    @extend .underline__dark-blue;
                }
            }
        }
    }
}

.customer-account-forgotpassword {
    .page-title-wrapper {
        .page-title {
            margin-bottom: 19px;
        }
    }

    .password.forget {
        > .fieldset {
            > .field {
                &.captcha {
                    margin-bottom: 40px;

                    .control.captcha {
                        @include lib-vendor-prefix-display(flex);
                        align-items: center;

                        @media screen and (max-width: $screen__m) {
                            flex-direction: column;
                        }

                        .input-text {
                            max-width: 220px;
                            margin-right: 25px;

                            @media screen and (max-width: $screen__m) {
                                max-width: 100%;
                                margin-right: 0;
                            }
                        }

                        .captcha-image {
                            margin: 0;
                            @include lib-vendor-prefix-display(flex);
                            align-items: center;

                            @media screen and (max-width: $screen__m) {
                                margin-top: 25px;
                            }

                            img {
                                max-width: 130px;
                            }

                            .captcha-reload {
                                @extend .button-as-link;
                                @extend .underline__dark-blue;

                                text-transform: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.account {
    .columns {
        justify-content: center;
    }

    .page-main {
        padding-top: 80px;
        box-sizing: border-box;
        max-width: $layout__max-width;
    }

    .columns > .page-title-wrapper {
        text-align: center;
        width: 100%;
        margin-bottom: 68px;

        @media screen and (max-width: $screen__m) {
            order: 2;
            margin: 40px 0;
        }

        .page-title,
        .order-status {
            font-size: 26px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            margin-right: 0;
            margin-bottom: 0;

            display: inline-block;

            &:before {
                content: " - ";
            }
        }

        .page-title:before {
            display: none;
        }

        .order-date {
            margin-top: 14px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: $c-bluey-grey;

            .label {
                font-weight: normal;
                font-size: 14px;
            }
        }
    }

    .page.messages {
        .messages {
            margin: 20px 0;
        }
    }

    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
        content: none;
    }

    .message.info {
        padding-left: 12px;
        border-radius: 3px;
        color: $c-dark;
        max-width: 540px;
        box-sizing: border-box;

        span:before {
            display: none;
        }
    }
    .page-main {
        padding-top: 80px;
        box-sizing: border-box;

        @media screen and (max-width: $screen__m) {
            padding-top: 20px;
        }

        .actions-toolbar {
            margin-left: 0;
            margin-top: 25px;
            display: block;

            .primary,
            .secondary {
                display: inline-block;
            }

            .primary {
                margin-right: 20px;
            }

            .action.back {
                color: $c-bluey-grey;
                margin-left: 20px;
                font-size: 14px;
                font-weight: normal;
                position: relative;
                padding: 0 3px 2px 3px;

                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $c-bluey-grey;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                &:before {
                    display: none;
                }

                &:hover {
                    text-decoration: none;
                }

                &:hover:after {
                    display: none;
                }
            }
        }
    }

    .sidebar.sidebar-main {
        width: 30%;
        max-width: 260px;
        padding-right: 0;
        margin-right: 90px;

        .page-title {
            position: relative;
            font-size: 20px;
            margin-bottom: 30px;
            margin-left: 10px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: left;

            @include icomoon("simple-arrow-down", "before", true, true) {
                font-size: 14px;
                color: $c-dark;
            }

            &:before {
                display: none;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: transform .24s ease-in-out;
            }
        }

        .nav.item {
            margin-bottom: 20px;

            span,
            strong,
            a {
                font-size: 14px;
                color: $c-dark;
            }

            &.current {
                font-weight: 500;
                margin-left: 26px;
            }
        }

        @media screen and (max-width: $screen__m) {
            order: 1;
            margin: 0;
            max-width: 100%;
            padding: 12px 17px;
            border-radius: 3px;
            border: solid 1px $c-pale-lilac;
            box-sizing: border-box;

            .page-title {
                cursor: pointer;
                width: 100%;
                margin: 0;
                line-height: normal;

                span {
                    font-size: 14px;
                    font-weight: normal;
                }

                &:before {
                    display: block;
                }

                &.opened {
                    &:before {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }
            }

            .nav.items {
                padding-left: 0;
                height: 0;
                overflow-y: hidden;
                transition: height .24s ease-in-out;

                &.opened {
                    margin-top: 20px;
                }

                .item {
                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.current {
                        display: none;
                    }
                }
            }
        }
    }

    .column.main {
        width: 70%;
        max-width: 768px;

        @media screen and (max-width: $screen__m) {
            order: 2;
        }


        form {
            > .fieldset {
                margin: 0;

                > .legend {
                    display: none;
                }
            }

            .fieldset > .field:not(.choice) .label {
                text-align: left;
                padding-bottom: 7px;
                width: 100%;
            }

            .field:not(.choice) {
                max-width: 540px;
                @include lib-vendor-prefix-display(flex);
                flex-direction: column;

                &.group-name {
                    flex-direction: row;

                    > .field {
                        margin: 0 10px;

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                    }

                    .field-name-firstname,
                    .field-name-lastname {
                        flex: 1;
                    }
                }

                > .control {
                    width: 100%;
                }

            }

            .field.choice {
                &:before {
                    display: none;
                }
            }

            .field.password-info {
                margin-top: 10px;

                p {
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }

            .field.password-info {
                margin-bottom: 40px;
            }

            .password-strength-meter {
                font-size: 12px;
                margin-top: 10px;
                background: $_password-default;
                padding: 6px 12px;
                border-radius: 3px
            }

            &.form-edit-account {
                > .fieldset {
                    > .legend {
                        display: block;
                    }
                }

                .field.choice {
                    display: none;
                }

                .field:not(.choice) {
                    &.group-name {
                        flex-direction: column;

                        > .field {
                            margin: 0 0 29px 0;
                        }
                    }
                }
            }
        }

        .block {
            margin-bottom: 70px;
        }

        .block,
        fieldset {
            position: relative;
        }

        .block-title,
        legend {
            margin-bottom: 27px;
            display: inline-block;
            padding-right: 36px;
            font-size: 20px;
            letter-spacing: 0.5px;

            strong,
            span {
                font-weight: normal;
            }

            .action {
                color: $c-bluey-grey;
                margin-left: 20px;
                font-size: 14px;
                font-weight: normal;
                position: relative;
                padding: 0 3px 2px 3px;

                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $c-bluey-grey;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &:hover:after {
                    display: none;
                }
            }
        }

        legend {

            &:after {
                top: 16px;
            }
        }

        .block-content {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
            }

            .box {
                width: 42%;

                @media screen and (max-width: $screen__m) {
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 27px;
                    }
                }

                &-title {
                    display: inline-block;
                    margin-bottom: 12px;
                    font-size: 16px;
                    font-weight: 500;

                }

                &-content {
                    font-size: 14px;

                     p {
                         margin-bottom: 0;
                     }
                }

                .action {
                    color: $c-bluey-grey;
                    display: inline-block;
                    margin-top: 16px;
                    font-size: 14px;
                    position: relative;
                    padding: 0 3px 2px 3px;

                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: $c-bluey-grey;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    &:hover:after {
                        display: none;
                    }
                }

                .action + .action {
                    margin-left: 24px;
                }
            }
        }

        .block-addresses-list {

            .block-content {
                display: block;
            }
        }
    }

    .form-wishlist-items,
    .form-edit-account,
    .form-address-edit,
    &.sales-order-history,
    &.customer-address-index,
    .form-newsletter-manage,
    .order-details-items {

        .actions-toolbar {
            .secondary {
                .action.back {
                    margin-left: 0;

                    &:before {
                        content: url('../images/icons/link-arrow-black.svg');
                        margin-right: 15px;
                        vertical-align: sub;
                    }
                }
            }
        }
    }

    .column.main .amgiftcard-form {
        width: 100%;
        max-width: none;
    }

    .amgiftcard .fieldset {
        width: 100%;

        .field {
            max-width: 640px;
        }
    }

    .order-actions-toolbar {
        padding-bottom: 20px;
        max-width: 100%;

        @media screen and (max-width: $screen__m) {
            padding-bottom: 0;
        }

        .actions {
            a {
                background: $c-pale-grey;
                border-radius: 4px;
                width: 49%;
                padding: 10px;
                text-align: center;
                display: inline-block;
                vertical-align: center;
                margin-right: 8px;
                box-sizing: border-box;

                @media screen and (max-width: $screen__m) {
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }

                span {
                    color: $c-dark;
                    font-size: 14px;
                    font-weight: normal;
                    position: relative;
                    padding: 0 3px 2px 3px;
                    display: inline-block;

                    &:not(:first-child) {
                        margin-left: 20px;
                    }

                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: $c-dark;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    &:hover:after {
                        display: none;
                    }
                }
            }
        }

        @media screen and (max-width: $screen__m) {
            max-width: 100%;
        }
    }

    .table-order-items {

        > tbody > tr > td:first-of-type,
        > thead > tr > th:first-of-type {
            padding-left: 0;
        }

        > tbody > tr > td:last-of-type,
        > thead > tr > th:last-of-type {
            padding-right: 0;
        }
    }

    &.sales-order-view {

        .column.main {
            display: flex;
            flex-direction: column;
        }

        .order-links {
            display: none;
        }

        @media screen and (max-width: $screen__m) {

            .column.main {

                .block-order-details-view {
                    margin-bottom: 40px;

                    .block-content {
                        display: flex;
                        flex-direction: column;

                        .box {
                            margin-bottom: 30px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .actions-toolbar {
            .secondary {
                background: $c-pale-grey;
                border-radius: 4px;
                padding: 10px;
                width: 100%;
                text-align: center;
                display: inline-block;
                vertical-align: center;
                margin-right: 8px;
                box-sizing: border-box;

                .action.back {
                    color: $c-dark;

                    &:after {
                        background: $c-dark;
                    }
                }
            }
        }

        .block-order-details-view {
            margin-top: 56px;

            .block-title {
                padding-bottom: 8px;
                border-bottom: solid 1px $c-pale-lilac;
                width: 100%;
                text-align: left;

                font-size: 20px;
                font-weight: normal;
                letter-spacing: 0.5px;
                padding-right: 0;
            }

            .block-content {
                display: block;

                > .box {
                    width: 45%;
                    display: inline-block;
                    vertical-align: top;

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }

    .order-details-items {
        border-bottom: 0;

        .order-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            border-bottom: solid 1px $c-pale-lilac;
            padding-bottom: 9px;
            margin-bottom: 70px;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 6px;
            }

            strong {
                font-weight: normal;
            }
        }

        .order-items {
            .table > thead > tr > th,
            .table > thead > tr > td,
            .table > tbody > tr > th,
            .table > tbody > tr > td {
                border-bottom: solid 1px $c-pale-lilac
            }

            .table > thead > tr > th:not(.name),
            .table > tbody > tr > td:not(.name) {
                text-align: center;

                @media screen and (max-width: $screen__m) {
                    text-align: left;
                }
            }

            .table > thead > tr > th.subtotal,
            .table > tbody > tr > td.subtotal {
                text-align: right;

                @media screen and (max-width: $screen__m) {
                    text-align: left;
                }
            }


            .col {
                &.price,
                &.subtotal {
                    .price-excluding-tax .cart-price .price {
                        font-size: 14px;
                        font-weight: normal;
                    }
                }

                &.qty {
                    .title {
                        display: none;
                    }
                }
            }

            .table > tfoot > tr > td:last-child {
                padding-right: 0;
            }

            @media screen and (max-width: $screen__m) {
                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
                    content: attr(data-th);
                    font-size: 16px;
                }

                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tfoot > tr.totals.tax {
                    .mark {
                        width: 70%;
                    }
                }

                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tfoot > tr th {
                    text-align: right;
                }

                .product-item-name {
                    padding: 0;
                }

                .item-options,
                .item-options dd {
                    margin-bottom: 0;
                }

                .item-options dt {
                    font-size: 16px;
                }

                .item-options dd,
                .item-options dt {
                    display: inline-block;
                    font-weight: normal;
                }
            }
        }
    }

    .orders-recent,
    .orders-history,
    .orders-hisales-order-viewstory,
    .table-order-items {
        width: 100%;

        .table > thead > tr > th,
        .table > thead > tr > td {
            border: none;
        }

        &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
        &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison)> tbody > tr th:before {
            font-weight: normal;
        }

        .col.actions {
            @media screen and (max-width: $screen__m) {
                &:before {
                    display: none !important;
                }
            }

            .action {
                color: $c-bluey-grey;
                font-size: 14px;
                font-weight: normal;
                position: relative;
                padding: 0 3px 2px 3px;
                display: inline-block;

                &:not(:first-child) {
                    margin-left: 20px;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $c-bluey-grey;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &:hover:after {
                    display: none;
                }
            }
        }
    }

    &.wishlist-index-index {
        .toolbar {
            display: none;

            &:last-of-type {
                display: block;
            }
        }
    }

    .order-products-toolbar,
    .wishlist-toolbar {
        margin-top: 50px;

        .pager {
            width: 100%;
            @include lib-vendor-prefix-display(flex);
            justify-content: space-between;
            align-items: center;
        }

        .limiter {
            @include lib-vendor-prefix-display(flex);
            justify-content: space-between;
            align-items: center;

            .limiter {
                &-label {
                    margin-right: 24px;
                    font-weight: normal;
                }

                &-text {
                    flex: 1;
                    min-width: 70px;
                    margin-left: 20px;
                }
            }
        }

        .toolbar-amount {
            font-weight: normal;
        }
    }
}

.customer-account-logoutsuccess {

    .column.main {
        text-align: center;
        margin-top: 60px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
