.catalog-category-view {
    .filter-options {
        .filter-attr_category_ids {
            .filter-options-title {
                font-size: 20px;
                letter-spacing: 0.5px;
                color: $c-dark;
                font-weight: normal;

                @media screen and (max-width: $screen__m) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $c-dark;
                }

                &:after {
                    display: none;
                }
            }

            .filter-options-content {
                .items {
                    margin-bottom: 40px;

                    @media screen and (max-width: $screen__m) {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .filter-option-wrapper:last-child {
            .filter-options-content {
                border-bottom: none;
            }
        }

        &-title {
            position: relative;
            padding-right: 20px;
            cursor: pointer;
            padding-top: 18px;
            margin: 0 0 18px;
            font-size: 14px;
            font-weight: 500;
            color: $c-dark;

            @media screen and (max-width: $screen__m) {
                padding-top: 38px;
            }


            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 18px;
                background-image: url('../images/icons/arrow-down.svg');
                -webkit-background-size: 16px;
                background-size: 16px;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                transition: transform .24s ease-in-out;

                @media screen and (max-width: $screen__m) {
                    display: none;
                }
            }

            &:hover,
            &.opened {

                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &-content {
            height: 0;
            overflow: hidden;
            transition: height 0.24s ease-in-out;
            margin-bottom: 0;
            border-bottom: 1px solid $c_text-lighter;

            .am-show-more {
                margin-top: 0;
                margin-bottom: 30px;
                border: none;
                padding: 0;
                background-color: transparent;
                font-size: 14px;
                color: $c_grey;
                text-decoration: underline;
                line-height: 50px;
            }

            > form {
                margin-bottom: 8px;
                display: inline-block;
                width: 100%;

                &[data-amshopby-filter="size"] {
                    width: auto;
                }

                > ol {
                    padding-top: 0;
                }

                @media screen and (max-width: $screen--l) {
                    width: 100%;

                    > ol {
                        width: 100%;

                        a {
                            display: block;
                            position: relative;
                            padding-right: 24px;
                            font-weight: normal;

                            input {
                                position: absolute;
                                right: 0;
                                top: 1px;
                            }
                        }
                    }
                }
            }

            .swatch-attribute {
                margin-bottom: 0;

                &-options {
                    margin-top: 12px;
                }
            }

            .swatch-attribute.size {


                .swatch-option.text {

                    &:hover {
                        border: 1px solid $c_black;
                    }
                }
            }

            .swatch-attribute.material_cost {
                flex-direction: column;

                .am-swatch-wrapper {
                    .am-swatch-link {
                        width: 100%;
                        height: 30px;
                        display: block;

                        @include material-cost(
                                "$$$$$",
                            $inner-selector: ".swatch-option.text",
                            $line-height: "30px",
                            $font-size: "inherit"
                        );

                        .swatch-option.text {
                            background: none;
                            border: none;
                            border-radius: 0;
                            margin: 0;
                            height: auto;
                        }
                    }

                    > input {
                        display: none;
                    }
                }

            }

            .swatch-option.text {
                background-color: #fff;
                border-radius: 4px;
                height: 40px;
                line-height: 30px;
                min-width: 67px;
            }

            .swatch-option.image,
            .swatch-option.color {
                width: auto;
                height: 30px;
                min-width: 30px;
                border-radius: 50%;

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 21px;
                    height: 21px;
                    transform: translate(-50%, -50%);
                    border: 2px solid $c-white;
                    border-radius: 100%;
                    opacity: 0;
                }

                &:hover,
                &.selected {

                    &:after {
                        opacity: 1;
                    }
                }
            }

            .items.am-filter-items-price {
                padding-top: 0;

                .amshopby-slider-container {
                    margin: 10px 15px 15px 5px;
                }

                .ui-slider,
                .ui-slider-range {
                    height: 4px;
                }

                .ui-slider-range,
                .ui-slider-handle {
                    background: #8c96a9;
                }

                .ui-slider {
                    background-color: $c_text-lighter;
                }

                .ui-slider-handle {
                    width: 16px;
                    height: 16px;
                    border: solid 2px #8c96a9;
                    background: $c-white !important;
                    margin-top: -12px;
                    margin-left: -5px;
                }

                .amshopby-slider-display {
                    margin-top: 16px;
                    font-size: 14px;
                    color: $c-dark;
                    text-align: center;
                }
            }

            [data-am-js="fromto-widget"] {
                padding-bottom: 30px;

                .range {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .am-filter-price {
                    font-size: 14px;
                    min-width: 66px;
                    padding: 12px;
                    text-align: center;
                }

                .am-filter-go {
                    //@extend .q_b-primary--border;
                    padding: 9px 0 !important;
                }
            }

            .items {
                padding-top: 12px;

                .item {
                    @include lib-vendor-prefix-display(flex);
                    align-items: flex-start;
                    margin-bottom: 16px;
                    position: relative;

                    a {
                        @include lib-vendor-prefix-display(flex);
                        align-items: flex-start;
                    }

                    .label {
                        color: $c-dark;
                        display: inline-block;
                        font-weight: normal;
                        font-size: 14px;
                    }

                    .count {
                        float: right;
                        line-height: 24px;
                        text-align: center;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        color: #bfc9cd;
                        margin-left: 10px;
                        margin-top: -3px;

                        .filter-count-label {
                            display: none;
                        }

                        &:before {
                            content: "(";
                        }

                        &:after {
                            content: ")";
                        }
                    }

                    &:last-child {
                        margin-bottom: 30px;

                        @media screen and (max-width: $screen__m) {
                            margin-bottom: 15px;
                        }
                    }

                    input[type="radio"],
                    input[type="checkbox"] {
                        width: 18px;
                        height: 18px;

                        @media screen and (max-width: $screen__m) {
                            border: solid 1px red;
                            position: absolute;
                            right: 0;
                            top: 0;
                            margin-right: 0;
                        }

                        &:before {
                            width: 18px;
                            height: 18px;
                            -webkit-background-size: 18px;
                            background-size: 18px;
                        }
                    }

                    a {
                        &:hover {
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &.am-filter-items-category_ids {

                    .item {

                        > a {
                            padding-left: 0;
                        }

                        input[type="radio"] {
                            display: none;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $screen--l) {

            &-content {

                .items {
                    padding-top: 0;
                }
            }
        }
    }

    .filter-options-content {
        .items {
            &.am-filter-items-category_ids .item input[type="radio"] {
                display: none;
            }
    
            .item a {
                order: 2;
            }
        }
        
        .am-swatch-wrapper > .input {
            display: none;
        }
    }
}

.catalog-category-view.page-layout-2columns-left,
.ambrand-index-index.page-layout-2columns-left,
.catalogsearch-result-index {

    .sorter-mobile,
    .mobile-filter-trigger {
        display: none;
    }

    .am_shopby_apply_filters {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        max-width: 315px;
        transition: opacity 0.48s ease-in-out;
        pointer-events: none;
        opacity: 0;
        z-index: 99999;

        .am-items {
            display: none;
        }

        .am-show-button {
            background-color: $c_white;
            padding: 25px 20px;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;

            a {
                margin-right: 20px;
            }

            button {
            }
        }
    }

    .sidebar-main {

        .sorter-mobile {
            display: block;

            @media screen and (max-width: $screen__m) {
                border-bottom: 1px solid $c-pale-lilac;
                padding-bottom: 23px;
            }

            .sorter-label {
                display: block;
                text-align: left;

                @media screen and (max-width: $screen__m) {
                    &:after {
                        display: none;
                    }
                }
            }

            &__options {
                display: flex;
                flex-direction: column;
                margin-top: 18px;

                .option {
                    display: inline-block;
                    position: relative;
                    font-size: 14px;
                    padding-bottom: 16px;
                }

                span {
                    display: block;
                    width: 100%;
                    padding-left: 32px;

                    @media screen and (max-width: $screen__m) {
                        padding-left: 0;
                    }
                }

                input {
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 0;
                    top: 1px;

                    @media screen and (max-width: $screen__m) {
                        left: auto;
                        right: 0;
                        margin-right: 0;
                    }

                    &:before {
                        width: 18px;
                        height: 18px;
                        -webkit-background-size: 18px;
                        background-size: 18px;
                    }
                }
            }
        }

        @media screen and (max-width: $screen__m) {
            min-width: 0;
            flex-grow: 1;
            flex-basis: 100%;
        }

        .am-show-form {
            display: inline-block;
            padding: 0;
        }

        .filter-current {
            display: none;

            .filter-current-subtitle,
            .filter-label {
                display: none;
            }
        }

        .filter-actions {

            > a {
                display: inline-block;
                padding: 4px 24px 4px 9px;
                color: $c_white;
                background-color: $c_black;
                border-radius: 4px;

                font-size: 12px;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-6px);
                    background-image: url('../images/icons/close.svg');
                    -webkit-background-size: 10px;
                    background-size: 10px;
                    background-repeat: no-repeat;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    @media screen and (max-width: $screen__m) {

        .sorter-mobile,
        .toolbar-products:first-of-type .mobile-filter-trigger {
            display: block;
        }

        .mobile-filter-trigger {
            border: solid 1px $c-pale-lilac;
            border-radius: 3px;
            text-align: center;

            a {
                padding: 17px 0;
                font-size: 14px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $c-dark;
                display: block;

                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .columns {
            flex-direction: column;
            padding: 0;

            .sidebar-main {
                display: none;
            }

            .column.main {
                width: 100%;
                flex-basis: 100%;
                padding-bottom: 60px !important;
            }
        }

        .toolbar-products {

            .sorter {
                @include abs-visually-hidden();
            }
        }

        .sidebar-main {
            .filter-title,
            .filter-subtitle {
                display: none;
            }

            .modal__wrapper {
                position: relative;
                max-width: 235px;
                padding: 30px 40px 0 40px;
                transform: none;
                border-radius: 0;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                min-height: 100vh;
                background-color: $c-white;

                .modal__close {
                    background-color: transparent;
                    position: absolute;
                    top: 15px;
                    right: -50px;
                    border-radius: 0;
                    width: 42px;
                    height: 42px;
                    z-index: 100;
                    cursor: pointer;

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 24px;
                        height: 24px;

                        path {
                            fill: $c-white;
                        }
                    }
                }
            }
        }
    }

    &.modal-opened .am_shopby_apply_filters {
        display: flex;
        opacity: 1;
        pointer-events: all;
    }

    &.page-with-filter--filtered {

        .columns {

            .filter-title h2 {
                margin-bottom: 31px;
            }

            .sidebar-main .block.filter {
                padding-top: 90px;

            }
        }
    }

}
// Custom modal
.modal {
    position: fixed;
    z-index: 10000;
    background: rgba($c_black, 0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.48s ease-in-out;
    overflow-y: auto;

    &.opened {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }

    &.closing {
        opacity: 0;
    }

    &__wrapper {
        position: absolute;
        width: 100%;
        max-width: 960px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        background-color: $c_white;
    }

    &__header {
        padding: 30px;
        text-align: center;
    }

    &__content {
        padding: 40px;
        position: relative;
    }

    &__footer {
        padding: 30px 40px 40px;
    }

    @media screen and (max-width: 768px) {
        overflow-y: auto;

        &__wrapper {
            max-width: none;
            max-height: none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
        }
    }

    @media screen and (max-width: 640px) {

        &__content {
            padding: 15px;
        }
    }
}
