.inspirationblog-page {
    .page-main {
        max-width: $layout__max-width;
    }

    .page-title-wrapper {
        text-align: center;
        margin-bottom: 45px;

        .page-title {
            font-size: 60px;
            font-weight: normal;
            letter-spacing: 0.25px;
            color: $c-dark;
            margin: 80px 0 0 0;

            @media screen and (max-width: $screen__m) {
                font-size: 28px;
                line-height: 1.21;
                letter-spacing: 0.5px;
            }
        }
    }

    .subtitle-blog {
        text-align: center;
        margin-bottom: 84px;

        p {
            font-size: 16px;
            font-weight: 500;

            @media screen and (max-width: $screen__m) {
                font-weight: normal;
            }
        }

        @media screen and (max-width: $screen__m) {
            margin-bottom: 40px;
        }
    }

    .block-categories {
        text-align: center;
        margin-bottom: 80px;

        .accordion {
            list-style: none;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin: 0 40px;

                @media screen and (max-width: $screen__m) {
                    margin: 0 20px 20px 20px;
                }

                a {
                    &.action {
                        padding: 0;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #858c8f;

                        &:after {
                            background: transparent;
                        }

                        &:hover {
                            color: $c-dark;
                        }
                        &:hover:after {
                            background: $c-dark;
                            display: inline-block;
                        }
                    }
                }
            }

            @media screen and (max-width: $screen__m) {
                display: none;
            }
        }

        .select-wrapper {
            display: none;

            .select-category {
                text-transform: uppercase;
            }

            @media screen and (max-width: $screen__m) {
                display: block;
            }
        }

        @media screen and (max-width: $screen__m) {
            margin-bottom: 40px;
        }
    }

    .category-content {
        text-align: center;
        padding-bottom: 40px;
    }

    .post-list-wrapper {
        .post-list {
            text-align: center;

            .post-holder {
                display: inline-block;
                width: calc(100% / 3);
                max-width: 370px;
                vertical-align: top;
                min-height: 460px;
                border-radius: 4px;
                border: solid 1px #ebebee;
                background-color: $c-white;
                margin-bottom: 40px;

                @media screen and (max-width: $screen__m) {
                    width: 100%;
                    max-width: fit-content;
                }

                &:not(:nth-child(3n)) {
                    margin-right: 50px;

                    @media screen and (max-width: $screen__m) {
                        margin-right: 0;
                    }
                }

                .post-content {
                    padding: 25px;
                    text-align: left;

                    .post-ftimg-hld {

                    }

                    .post-info {
                        .post-title {
                            margin: 0 0 18px 0;
                            font-size: 24px;
                            font-weight: normal;
                            line-height: normal;
                            letter-spacing: 1px;

                            .post-item-link {
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }

                        .post-categories {
                            padding: 10px 0 15px 0;
                            color: #858c8f;

                            a {
                                color: #858c8f;
                            }
                        }
                    }

                    .post-text-hld {
                        display: none;
                    }
                }
            }
        }
    }

    // pagination

    .toolbar-blog-posts {
        .pages {
            margin: 80px auto 147px auto;

            .pages-items {
                margin-right: 2px;

                .item {
                    text-align: center;

                    &.current .page {
                        border-radius: 100%;
                        background-color: $c-black
                    }

                    .page {
                        background-color: transparent;
                    }

                }
            }

            .pages-item-previous a:hover,
            .pages-item-next a:hover {
                background-color: $c-black
            }
        }
    }
}

// Inspiration Blog Post View
.inspirationblog-post-view {

    .page-main {
        padding-left: unset;
        padding-right: unset;
    }

    .page-title-wrapper {
        z-index: 1;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .page-title {
            color: $c-white;

            @media screen and (max-width: $screen__m) {
                font-size: 32px;
                line-height: 1.06;
                letter-spacing: 0.5px;
            }
        }
    }

    .block-categories {
        display: none;
    }

    .post-view {

        .post-holder {

            .post-header {
                display: none;
            }
        }

        .post-ftimg-hld {

            img {
                width: 100%;
                height: 740px;
                object-fit: cover;
                object-position: center;
                filter: brightness(75%);
            }
        }

        .post-text-hld {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
            padding-left: 40px;
            padding-right: 40px;

            @media screen and (max-width: $screen__m) {
                padding-left: 20px;
                padding-right: 20px;
                margin-top: 40px;
            }

            .project-name {
                z-index: 1;
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);

                @media screen and (max-width: $screen__m) {
                    top: 48%;
                }

                p {
                    font-family: $font-family-name__base;
                    letter-spacing: 0.5px;
                    color: $c-white;
                    text-transform: uppercase;
                }
            }

            .social-sharing-link {
                z-index: 1;
                position: absolute;
                top: 65%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;

                div {
                    display: flex;
                    align-items: center;
                    margin: 1rem;
                    padding: 1rem;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                }
            }

            .post-text-promo {
                margin-bottom: 100px;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 50px;
                }
            }

            .post-text-location {
                color: $c-bluey-grey;
                margin-bottom: 3rem;
            }

            .post-more-informations {
                margin-top: 5rem;
                max-width: 700px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: baseline;

                @media screen and (max-width: $screen__m) {
                    padding-left: 2rem;
                    flex-wrap: wrap;
                }

                > div {
                    height: 80px;
                    margin: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    display: flex;
                    justify-content: space-between;

                    &:not(:last-child) {
                        border-right: 1px solid #bfc9cd;
                        padding-right: 3rem;

                        @media screen and (max-width: $screen__m) {
                            border: unset;
                        }
                    }

                    h3 {
                        margin: unset;

                        span {
                            text-decoration: none;
                        }
                    }

                    .name {
                        text-align: center;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                .colors {
                    > div {
                        display: flex;
                        height: 65%;

                        div {
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            display: inline-block;
                        }

                        .medium-brown{
                            align-self: flex-end;
                            background-color: #67331a;
                        }

                        .brown {
                            background-color: #521515;
                        }

                        .dark-brown {
                            align-self: flex-end;
                            background-color: #240505;
                        }
                    }
                }

                @media screen and (max-width: $screen__m) {
                    .cost {
                        padding-right: 2rem;
                    }
                }
            }

            .post-materials {
                margin-top: 5rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                .title {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #1c2028;
                    margin-bottom: 20px;
                }

                .materials-pannel {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 350px;
                    width: 100%;

                    img {
                        width: 38px;
                        height: auto;
                    }

                    > div {
                        height: 82px;
                        margin: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 12px;
                        }
                    }

                    .pattern {
                        height: 38px;
                        width: 38px;
                        border-radius: 50%;
                       display: inline-block;
                        background-position: center;
                    }

                    .chene-clair {
                        background-image: url('../images/materials/chene-clair.png');
                    }

                    .pin-moyen {
                        background-image: url('../images/materials/pin-moyen.png');
                    }

                    .melanine-blanche {
                        background-image: url('../images/materials/melanine-blanche.png');
                    }

                    .bois-peint {
                        background-image: url('../images/materials/bois-peint.png');
                    }

                    .chakata-noir-ebene {
                       background-image: url('../images/materials/chakata-noir-ebene.png');
                    }
                }
            }

            h3 {
                margin-bottom: 30px;
                letter-spacing: 0.5px;
                font-size: 32px;
                font-weight: normal;
                text-align: center;

                @media screen and (max-width: $screen__m) {
                    max-width: 250px;
                    font-size: 28px;
                    line-height: 1.21;
                    letter-spacing: 0.5px;
                }
            }

            h2 {
                text-align: center;
            }

            p {
                max-width: 700px;
                text-align: center;
                font-size: 14px;
                line-height: 1.71;
            }
        }
    }

    .social-links {
        margin-top: 5rem;
        display: flex;

        @media screen and (max-width: $screen__m) {
            margin-top: 3rem;
        }

        div {
            display: flex;
            align-items: center;
            margin: 1rem;
            padding: 1rem;
            border-radius: 50%;
            border: 1px solid #000;
        }
    }

    .post-gallery {
        margin-top: 10rem;
        width: 100%;
        display: flex;

        @media screen and (max-width: $screen__m) {
            flex-direction: column;
        }

        .column {
            padding: 10px 5px;

            @media screen and (max-width: $screen__m) {
                padding: unset;
                display: flex;
                width: 47%;
            }

            > img {
                margin-bottom: 5px;

                @media screen and (max-width: $screen__m) {
                    margin: unset;
                    padding: 5px;
                    object-fit: cover;
                }
            }
        }
    }

    .post-contact-us {
        margin-top: 10rem;
        display: flex;

        @media screen and (max-width: $screen__m) {
            margin-top: 7rem;
            flex-direction: column-reverse;
            align-items: center;
        }

        > img {
            width: 50%;
            height: auto;

            @media screen and (max-width: $screen__m) {
                display: none;
            }
        }

        > div {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: $screen__m) {
                width: 100%;
            }

            .contact-action {
                border-radius: 1px;
                border: solid 1px $c_text-dark;
                padding: 14px 29px;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                text-align: center;
                color: $c_text-dark;
                line-height: 1rem;
            }

            hr {
                border-top: 1px solid #979797;
                width: 20%;
                margin-bottom: 20px;
                margin-top: 40px;
            }
        }
    }

    .post-suggestions {
        margin: 10rem 10rem 0 10rem;
        display: flex;

        @media screen and (max-width: $screen__m) {
            margin: 10rem 0 0 0;
            flex-direction: column;
        }

        .content-box {
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            @media screen and (max-width: $screen__m) {
                margin: unset;
                align-items: center;
            }

            > img {
                width: 320px;
                height: auto;
            }

            > h4 {
                margin: 4rem 0;
                font-size: 24px;
                font-weight: normal;
                letter-spacing: 1px;
                color: $c_text-dark;

                @media screen and (max-width: $screen__m) {
                    text-align: center;
                }
            }

            > p {
                text-align: left;
            }

            .read-more {
                text-transform: uppercase;
                letter-spacing: 1.5px;
                color: $c_text-dark;
                font-weight: 500;
                font-size: 12px;

                &::after {
                    display: inline-block;
                    content: "";
                    border-top: 1px solid black;
                    width: 34px;
                    margin: -6px 2rem;
                    transform: translateY(-1rem);

                    @media screen and (max-width: $screen__m) {
                        display: none;
                    }
                }

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 5rem;
                    text-align: center;
                    text-decoration: underline;
                }
            }
        }
    }
}

.blog-widget-recent-masonry {
    .post-list {
        margin: 0 auto !important;
        max-width: 100%;

        .post-holder {
            margin: 6px auto;
        }
    }

    .title {
        display: none;
    }
}
