$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @include lib-vendor-prefix-display(flex);
    justify-content: space-between;
    align-items: center;

    .sorter-label {
        display: none;
    }

    &__left {
        @include lib-vendor-prefix-display(flex);
        align-items: center;

        @media screen and (max-width: $screen__m) {
            margin-bottom: 3rem;
        }
    }

    &__right {
        @media screen and (max-width: $screen__m) {
            width: 100%;

            .sorter-options {
                width: 100%;
            }
        }
    }
}

.toolbar-amount {
    display: block;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: $c-dark;

    @media screen and (max-width: $screen__m) {
        display: flex;
        flex-direction: row-reverse;

        .toolbar-number {
            margin-left: 5px;
            margin-right: 5px;
        }

        .toolbar-numbers {
            display: none;
        }
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    margin-bottom: $indent__xl;
    text-align: center;

    @media screen and (max-width: $screen__m) {
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
    }

    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    @include lib-vendor-prefix-display(flex);
    justify-content: center;
    align-items: center;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-label {
    font-size: 14px;
    font-weight: 500;
    color: $c-dark;
    flex: 1;

    &:after {
        content:' : ';
    }
}
.select-wrapper {
    flex: 1;

    &:after {
        font-size: 18px;
    }

    &:focus {
        box-shadow: none;
    }
}
.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
    padding: 15px 30px 15px 15px;
    border-radius: 3px;
    border: solid 1px #e9e9eb;
    box-shadow: none;
}

.modes {
    display: none;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .page-products {

        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    //.toolbar {
    //    .products.wrapper ~ & .pages {
    //
    //    }
    //}

    .modes {
        display: flex;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $c-dark;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        border: 1px solid $c-dark;
        border-radius: 4px;
        background-color: $c-white;
        margin-right: 5px;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $c-dark,
            $_icon-font-color-hover: $c-dark,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                background: $c-dark;
                color: $c-white;

                &:before {
                    color: $c-white;
                }
            }
        }

        &.active {
            background-color: $c-dark;
            color: $c-white;

            &:before {
                color: $c-white;
            }
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }
}
