@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?") format("eot"),
    url("../fonts/icomoon.woff2") format("woff2"),
    url("../fonts/icomoon.woff") format("woff"),
    url("../fonts/icomoon.ttf") format("truetype"),
    url("../fonts/icomoon.svg#Samplino") format("svg");
}


@mixin icomoon($icon, $position: "before", $font-size: false, $color: false) {
    // Pseudo-element properties
    &:#{$position} {
        @extend .icon-#{$icon};
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
        @content;
    }
}

[class*="icon-"].icon {
    &:before{
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
    }
}
