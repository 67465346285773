a,
.link,
a:visited {
    color: $c-battleship-grey;
}

a:hover,
.link:hover {
    color: $c-dark;
    cursor: pointer;
    text-decoration: underline;
}

.grey,
a.grey,
.grey:visited {
    color: $c-bluey-grey;
    text-decoration: underline;

    &:hover {
        color: $c-dark;
        text-decoration: underline;
    }
}

.button-as-link {
    padding: 0;
    border: none;
    border-radius: 0;
    font-weight: normal;
    color: $c-dark;
    background-color: transparent;

    &:hover {
        color: $c-cloudy-blue;
        border: 0;
        background-color: transparent;
        text-decoration: underline;
    }
}

.primary--full,
.primary--full:visited {
    display: inline-block;
    padding: 16px 53px;
    color: $c-white;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    font-weight: 600;
    border: 1px solid $c-dark;
    border-radius: 0;
    background-color: $c-dark-green;
    text-align: center;

    &:hover {
        text-decoration: none;
        background-color: darken($c-dark, 20%);
        border: 1px solid darken($c-dark, 20%);
        color: $c-white;
    }
}

.primary--full-blue {
    background-color: $c-clear-blue;
    border-color: $c-clear-blue;
    text-align: center;

    &:hover {
        background-color: darken($c-clear-blue, 20%);
        border-color: $c-clear-blue;
    }
}

.primary--border,
.primary--border:visited {
    color: $c-dark;
    background-color: $c-white;
    border: 1px solid $c-dark;
    text-transform: uppercase;
    padding: 16px 53px;
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    font-weight: 600;
    border-radius: 0;
    text-align: center;

    &:hover {
        text-decoration: none;
        background-color: $c-dark;
        border-color: $c-dark;
        color: $c-white;
    }
}

a.secondary--underline,
.secondary--underline {
    position: relative;
    color: $c-dark;
    padding-bottom: 2px;
    font-size: 1.4rem;
    display: inline-block;

    &:after {
        content: "";
        display: block;
        height: 1px;
        left: 0;
        right: 0;
        bottom: -2px;
        background-color: $c-dark;
        transform: scaleX(1);
        transition: transform 0.24s ease;
    }

    &:hover {
        text-decoration: none;
        color: $c-slate;

        &:after {
            transform: scaleX(0.3);
            background-color: $c-slate;
        }
    }
}

a.secondary--underline-black,
.secondary--underline-black {
    @extend .secondary--underline;
    color: $c-black;

    &:after {
        background-color: $c-black;
    }
}

.action.primary,
button {
    background: $c-dark;
    border: solid 1px $c-dark;
    padding: 17px 54px;
    text-transform: uppercase;
    color: $c-white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 14px;
    text-align: center;

    &:hover {
        background: $c-white;
        border: solid 1px $c-dark;
        color: $c-dark;
    }

    &.white {
        background: $c-white;
        border: solid 1px $c-dark;
        color: $c-dark;

        &:hover {
            background: $c-dark;
            border: solid 1px $c-dark;
            color: $c-white;
        }
    }
}

.action.secondary {
    &.underline {
        position: relative;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: $c-dark;
        text-transform: uppercase;
        padding: 0 5px 3px 5px;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: $c-dark;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover:after {
            display: none;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.underline__dark-blue {
    color: $c-bluey-grey;
    margin-left: 20px;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    padding: 0 3px 2px 3px;

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $c-bluey-grey;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &:before {
        display: none;
    }

    &:hover {
        text-decoration: none;
    }

    &:hover:after {
        display: none;
    }
}

button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}

a.action.primary {
    @include lib-link-as-button();
}

.button--transparent {
    &-white {
        color: $c-white !important;
        border: solid 1px $c-white !important;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-align: center;
        text-transform: uppercase;
        padding: 18px 54px;
        background: transparent;
        border-radius: 1px;
    }

    &-green {
        color: $c-dark-green !important;
        border: solid 1px $c-dark-green !important;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-align: center;
        text-transform: uppercase;
        padding: 18px 54px;
        background: transparent;
        border-radius: 1px;
    }
}
