//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l !default;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height !default;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color !default;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-settings !default;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px !default;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color !default;

//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    margin-top: 60px;

    .shipping-information-title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 15px;
        font-size: 2rem;
        letter-spacing: 0.5px;
        line-height: 1;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        .action-edit {
            @extend .button-as-link;
            @extend .secondary--underline;

            text-transform: capitalize;
            color: $c-bluey-grey;

            &:after {
                background-color: $c-bluey-grey;
            }

        }
    }

    .shipping-information-content {
        line-height: 1.5;

        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }

    .ship-via {
        margin-top: 30px;
    }
}
