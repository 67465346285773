//Slider

@mixin flex-banner-positions {
    // Default to center center
    align-items: center;
    justify-content: center;
    text-align: center;

    &--top-left {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }

    &--center-left {
        align-items: center;
        justify-content: flex-start;
        text-align: left;
    }

    &--bottom-left {
        align-items: flex-end;
        justify-content: flex-start;
        text-align: left;
    }

    &--top-center {
        align-items: flex-start;
        justify-content: center;
        text-align: center;
    }

    &--bottom-center {
        align-items: flex-end;
        justify-content: center;
        text-align: center;
    }

    &--top-right {
        align-items: flex-start;
        justify-content: flex-end;
        text-align: right;
    }

    &--center-right {
        align-items: center;
        justify-content: flex-end;
        text-align: right;
    }

    &--bottom-right {
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
    }

    @media screen and (max-width: $screen__l) {

        &--top-left {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--center-left {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--bottom-left {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--top-center {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--bottom-center {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--top-right {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--center-right {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--bottom-right {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }
    }
}

@mixin mobile-banner-image {

    img.mobile {
        display: none;
    }

    @media screen and (max-width: $screen__l) {
        position: relative;
        background-image: none !important;
        min-height: inherit;
        align-items: flex-start;

        img.mobile {
            display: block;
            width: 100%;
            height: auto;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($c-black, 0.5);
        }

        .content {
            z-index: 1;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 30px;
            box-sizing: border-box;

            .text {
                color: $c-white;
            }
        }
    }
}
.cms-index-index {
    .top-container {
        padding: 0;
        margin: 0;
    }
}

.home__slider-banner {
    .slide {
        min-height: 740px;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: stretch;
        @include mobile-banner-image();
    }

    .content {
        width: 100%;
        max-width: 984px;
        margin: 0 auto;
        padding: 75px 0;
        display: flex;
        @include flex-banner-positions();

        .text {
            .subtitle {
                font-size: 14px;
                letter-spacing: 0.5px;
                color: $c-white;
                padding-bottom: 0;
                margin-bottom: 0;
                text-transform: uppercase;
            }

            .title {
                font-size: 60px;
                letter-spacing: 0.25px;
                color: $c-white;
                padding-bottom: 0;
                margin-bottom: 0;
                line-height: 70px;

                @media screen and (max-width: $screen__m) {
                    font-size: 32px;
                    line-height: 1.06;
                    letter-spacing: 0.5px;
                    max-width: 60%;
                    margin: 16px auto;
                }
            }

            .q_b-primary {
                display: inline-block;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.5px;
                text-align: center;
                color: $c-white;
                border: solid 1px $c-white;
                text-transform: uppercase;
                padding: 18px 54px;
                background: transparent;
                border-radius: 1px;
                margin-top: 40px;

                @media screen and (max-width: $screen__m) {
                    margin-top: 0;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &:not(.owl-carousel) {
        .slide {
            display: none;

            &:first-of-type {
                display: flex;
            }
        }
    }

    &.owl-theme {
        .owl-dots {
            width: 100%;
            position: absolute;
            bottom: 0;
            padding: 55px 0;

            @media screen and (max-width: $screen__m) {
                padding: 40px 0;
            }

            .owl-dot {
                margin: 0 5px;

                span {
                    width: 6px;
                    height: 6px;
                    background-color: rgba(34, 34, 34, 0.15);
                    position: relative;
                    border-radius: 100%;
                }

                &.active {
                    span {
                        background-color: $c_white;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

// Full Width Banner

.home-full-width-banner {
    position: relative;
    margin-left: -$new-layout-padding;
    margin-right: -$new-layout-padding;

    @media screen and (max-width: $screen__m) {
        margin-left: -20px;
        margin-right: -20px;
    }

    .desktop {
        display: block;
        width: 100%;

        @media screen and (max-width: $screen__m) {
            display: none;
        }
    }

    .mobile {
        display: none;

        @media screen and (max-width: $screen__m) {
            display: block;
        }
    }

    .inner-block {
        max-width: 500px;
        max-height: 300px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        align-items: center;

        &.block-position-middle_center {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &.block-position-middle_left {
            top: 50%;
            left: 5%;
            right: unset;
            transform: translateY(-50%);
        }

        &.block-position-middle_right {
            top: 50%;
            right: 5%;
            left: unset;
            transform: translateY(-50%);
        }

        &.block-position-top_left {
            top: 5%;
            left: 5%;
            right: unset;
        }

        &.block-position-top_right {
            top: 5%;
            left: unset;
            right: 5%;
        }

        &.block-position-top_center {
            top: 5%;
            left: 50%;
            transform: translateX(-50%);
        }

        &.block-position-bottom_left {
            top: unset;
            bottom: 5%;
            left: 5%;
            right: unset;
        }

        &.block-position-bottom_right {
            top: unset;
            bottom: 5%;
            left: unset;
            right: 5%;
        }

        &.block-position-bottom_center {
            top: unset;
            bottom: 5%;
            left: 50%;
            transform: translateX(-50%);
        }

        .content-block {
            .subtitle {
                font-size: 14px;
                letter-spacing: 0.5px;
                color: $c-white;
                text-transform: uppercase;
                padding-bottom: 0;
                margin-bottom: 0;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 17px;
                }
            }

            .title {
                font-size: 32px;
                letter-spacing: 0.5px;
                color: $c-white;
                padding-bottom: 0;
                margin-bottom: 0;

                @media screen and (max-width: $screen__m) {
                    font-size: 28px;
                    margin-bottom: 24px;
                }
            }

            .action.primary {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.5px;
                text-align: center;
                color: $c-white;
                border: solid 1px $c-white;
                text-transform: uppercase;
                padding: 18px 54px;
                background: transparent;
                border-radius: 1px;
                margin-top: 30px;

                @media screen and (max-width: $screen__m) {
                    margin-top: 0;
                }
            }
        }

        .text-align-center {
            text-align: center;
        }

        .text-align-left {
            text-align: left;
        }

        .text-align-right {
            text-align: right;
        }
    }
}
