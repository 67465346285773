//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border         : $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding        : $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding : 15px !default;

$checkout-payment-method-content__padding__xl  : 22px !default;

$checkout-billing-address-details__line-height : 27px !default;
$checkout-billing-address-details__padding     : 0 0 0 23px !default;
$checkout-billing-address-form__max-width      : $checkout-shipping-address__max-width !default;

//
//  Common
//  _____________________________________________


.checkout-payment-method {

    .step-title {
        border-bottom: 0;
        margin-bottom: 0;
    }

    .payment-method {

        &:first-child {

            .payment-method-title {
                border-top: 0;
            }
        }

        &._active {

            .payment-method-content {
                display: block;
            }
        }
    }

    .payment-method-content {

        > .actions-toolbar {

            > .primary {

                .action {

                    &.primary {
                        @extend .primary--full;
                    }
                }
            }
        }
    }

    .payment-method-title {
        margin: 0;

        .payment-icon {
            display: inline-block;
            margin-right: $indent__xs;
            vertical-align: middle;
        }

        .action-help {
            display: inline-block;
            margin-left: $indent__xs;
        }
    }

    .payment-method-content {
        display: none;

        .fieldset {

            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }
    }

    .payment-group {

        & + .payment-group {

            .step-title {
                margin: $indent__base 0 0;
            }
        }
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .checkout-billing-address {
        margin: 0 0 $indent__base;

        .checkout-billing-address {

            .primary {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .action-update {
                @extend .primary--full;
            }

            .action-cancel {
                @extend .button-as-link;
                @extend .secondary--underline;
                text-transform: capitalize;
                color: $c-bluey-grey;

                &:after {
                    background-color: $c-bluey-grey;
                }
            }
        }

        .billing-address-details {
            margin-top: 20px;
            line-height: 1.5;

            .action-edit-address {
                @extend .button-as-link;
                @extend .secondary--underline;
                margin-top: 15px;
                text-transform: capitalize;
                color: $c-bluey-grey;

                &:after {
                    background-color: $c-bluey-grey;
                }
            }
        }
    }

    .payment-method-note {

        & + .checkout-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {

        > .label {
            @extend .abs-visually-hidden;
        }

        > .control {
            @extend .select-wrapper;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .checkout-payment-method {

        .actions-toolbar {

            .primary {
                float: right;
                margin: 0;
            }
        }

        .fieldset {

            > .field-select-billing {

                > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {

            .fieldset {

                > .field {
                    margin: 0 0 $indent__base;

                    &.type {

                        .control {
                            margin-left: 25.8%;
                        }

                        &.no-detection {

                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
