//Default width classes
.w950 {
    max-width: 950px;
    margin: 0 auto;
}

.full-width-mobile {
    @media screen and (max-width: $screen__m) {
        margin-left: -20px;
        margin-right: -20px;
    }
}

// General classes for formatting
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.white {
    color: $c-white;
}

.dark-green {
    color: $c-dark-green;
}

.cms-page-view {
    @media screen and (max-width: $screen__m) {
        .page-main {
            padding-left: 20px;
            padding-right: 20px;

            iframe {
                width: 100%;
                height: auto;
            }
        }
    }

    .page-title-wrapper {
        text-align: center;

        .page-title {
            padding: 94px 0 60px 0;
            font-size: 60px;
            font-weight: normal;
            letter-spacing: 0.25px;
            text-align: center;

            @media screen and (max-width: $screen__m) {
                padding: 34px 0 40px 0;
                font-size: 28px;
                line-height: 1.21;
                letter-spacing: 0.5px;
            }

            @media screen and (max-width: 1025px) and (min-width: 768px) {
                font-size: 40px;
                line-height: 35px;
            }
        }
    }

    .page-subtitle {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: normal;
        color: $c-dark;
        margin: 0 0 80px 0;

        @media screen and (max-width: $screen__m) {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.71;
            margin: 0 0 35px 0;
        }
    }

    .page-main {
        table {
            thead {
                tr {
                    border-bottom: solid 1px $c-dark;

                    th {
                        padding: 10px 10px 10px 0;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                        font-weight: normal;
                    }
                }
            }

            tbody {
                tr {
                    th,
                    td {
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                        padding: 10px 12px;
                        font-weight: normal;
                    }

                    td {
                        font-weight: 500;
                    }

                    &:first-of-type {
                        td,
                        th {
                            padding-top: 26px;
                        }
                    }

                    &:nth-child(2n) {
                        background: #F9F9FB;
                    }
                }
            }

            tfoot {

            }
        }
    }
}

// Services Page

.cms-page-view .w950 {
    .page-main {
        @media screen and (max-width: $screen__m) {
            padding-left: 0;
            padding-right: 0;

            .page-subtitle {
                margin: 0 25px 35px 25px;
            }
        }
    }
}

.service-section {
    &:not(:last-child) {
        margin-bottom: 100px;
    }

    @media screen and (max-width: $screen__m) {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }

    .service-block {
        @include lib-vendor-prefix-display(flex);
        flex-direction: row;
        justify-items: center;
        align-items: center;

        @media screen and (max-width: $screen__m) {
            flex-direction: column-reverse;
        }

        .inner-block {
            width: 48%;

            @media screen and (max-width: $screen__m) {
                width: 100%;
                max-width: 450px;
                text-align: center;
            }

            &:nth-child(2n) {
                width: 52%;

                @media screen and (max-width: $screen__m) {
                    width: 100%;
                    max-width: 450px;
                }
            }

            &.text-block {
                text-align: right;
                padding-right: 46px;
                max-width: 376px;

                @media screen and (max-width: $screen__m) {
                    text-align: center;
                    padding: 20px 0;
                    margin-top: 10px;
                }

                .title {
                    font-size: 24px;
                    letter-spacing: 1px;
                    margin-bottom: 15px;
                }

                .description {
                    font-size: 14px;
                    line-height: 1.71;
                    margin-bottom: 13px;

                    @media screen and (max-width: $screen__m) {
                        margin: 0 22px 13px 22px;
                    }
                }

                .action {

                }
            }
        }

        &-inverted {
            flex-direction: row-reverse;

            @media screen and (max-width: $screen__m) {
                flex-direction: column-reverse;
            }

            .inner-block {
                text-align: right;

                &.text-block {
                    text-align: left;
                    padding-right: 0;
                    padding-left: 46px;

                    @media screen and (max-width: $screen__m) {
                        text-align: center;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}

// No route 404 page
.cms-noroute-index {
    .no-route-block {
        @include lib-vendor-prefix-display(flex);
        flex-direction: column;
        margin-top: 80px;
        margin-bottom: 300px;

        @media screen and (max-width: $screen__m) {
            margin: 100px 0;
        }

        .error-text {
            font-size: 300px;
            font-weight: 900;
            letter-spacing: 6px;
            text-align: center;

            @media screen and (max-width: $screen__m) {
                font-weight: 700;
                font-size: 160px;
            }

            span {
                font-weight: 300;
            }
        }

        .text-block {
            margin-top: 24px;
            font-size: 28px;
            letter-spacing: 0.56px;
            text-align: center;

            @media screen and (max-width: $screen__m) {
                margin: unset;
                font-size: 20px;
                line-height: 1.2;
                letter-spacing: 0.5px;
            }
        }

        .actions-block {
            margin-top: 45px;
            text-align: center;
        }
    }
}


// Stores Page
.stores-map-section {
    margin-top: 40px;

    @media screen and (max-width: $screen__m) {
        display: none;
    }
}

.store-section {
    .store-block {
        @include lib-vendor-prefix-display(flex);
        flex-direction: row;
        justify-items: center;
        align-items: center;

        @media screen and (max-width: $screen__m) {
            flex-direction: column-reverse;
        }

        .inner-block {
            width: 50%;
            margin-top: -5px;

            @media screen and (max-width: $screen__m) {
                width: 100%;
                text-align: center;
            }

            &.text-block {
                text-align: right;

                @media screen and (max-width: $screen__m) {
                    text-align: center;
                    padding: 30px 0 60px 0;
                }

                .name,
                .address {
                    padding-right: 80px;

                    @media screen and (max-width: $screen__m) {
                        padding-right: 0;
                    }
                }

                .name {
                    font-size: 24px;
                    letter-spacing: 1px;
                    line-height: 28px;
                    margin-bottom: 15px;
                }

                .address {
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;
                    margin-bottom: 15px;
                }
            }
        }

        &-inverted {
            flex-direction: row-reverse;

            @media screen and (max-width: $screen__m) {
                flex-direction: column-reverse;
            }

            .inner-block {
                &.text-block {
                    text-align: left;

                    .name,
                    .address {
                        padding-right: 0;
                        padding-left: 80px;

                        @media screen and (max-width: $screen__m) {
                            padding-left: 0;
                        }
                    }

                    @media screen and (max-width: $screen__m) {
                        text-align: center;
                        padding: 30px 0 60px 0;
                    }
                }
            }
        }
    }
}

// Content Page Template
.content-page-template {
    @media screen and (max-width: $screen__m) {

        h2 {
            text-align: center;
            margin: 40px 0 15px 0;
        }

        table {
            tbody {
                tr {
                    th {
                        padding: 8px 0px !important;
                    }
                }
            }
        }

        a {
            margin-top: 2rem;
        }
    }
}


.form-success-section {
    @include lib-vendor-prefix-display(flex);
    margin: 80px auto 84px auto;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: $screen__m) {
        margin: 40px auto 40px auto;
    }

    .icon-block svg {
        @media screen and (max-width: $screen__m) {
            width: 50%;
        }
    }

    .title-block {
        margin-top: 80px;
        margin-bottom: 0;
        font-size: 30px;
        line-height: 35px;
        color: $c-dark;

        @media screen and (max-width: $screen__m) {
            margin-top: 40px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    .subtitle-block {
        margin-top: 33px;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 18px;
        color: $c_grey;

        @media screen and (max-width: $screen__m) {
            margin-top: 20px;
        }
    }
}

.cms-block-form {
    .content-page-template {
        text-align: center;
    }

    .form-block {
        max-width: 800px;
        margin: auto;

        .fieldset {
            .title {
                text-align: center;
                font-weight: 400;
                font-size: 32px;
                letter-spacing: 0.5px;
                line-height: 38px;
                margin: 0;
            }

            .subtitle {
                text-align: center;
                font-weight: 400;
                margin: 20px 0 30px 0;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
            }

            .field.name, .field.last-name, .field.email, .field.phone, .control {
                margin: 0 16px 20px;
                width: 100%;
                max-width: 46%;
                display: inline-block;
                box-sizing: border-box;

                @media screen and (max-width: $screen__m) {
                    max-width: 93%;
                }
            }

            .field.required, .control.required {
                input {
                    border-left: solid 3px rgba(244, 40, 55, 0.6);
                }
            }

            .field.contractor {
                margin: 0 16px 20px;

                @media screen and (max-width: $screen__m) {
                    margin: 0 7px 20px 16px;
                }
            }

            .field.captcha {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 16px;

                @media screen and (max-width: $screen__m) {
                    flex-direction: column;
                }

                .text, .img {
                    width: 50%;
                    margin: 0 16px 20px;

                    @media screen and (max-width: $screen__m) {
                        width: 93%
                    }
                }

                .img {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    a {
                        &:after {
                            content: "";
                            display: block;
                            height: 1px;
                            left: 0;
                            right: 0;
                            bottom: -2px;
                            background-color: #1c2028;
                            transform: scaleX(1);
                            transition: transform 0.24s ease;
                        }


                        &:hover {
                            text-decoration: none;

                            &:after {
                                transform: scaleX(0.3);
                                background-color: #434c5e;
                            }
                        }
                    }
                }
            }
        }

        .actions-toolbar {
            text-align: center;
        }
    }
}

/* Carrer CMS Page */

.carrer-position-section {
    margin: 60px auto;

    @media screen and (max-width: 767px) {
        flex-direction: column;

        .advantage {
            max-width: 100%;
        }
    }

    p.text.location-position-carreer {
        text-align: left !important;
    }

    ul.list-position-carreer {
        text-align: left;
        padding: 0;
    }
}
