.inspirations-page-view {

    .category-view {
        .category-description-image-wrapper {
            margin-bottom: 0;

            .category-description-wrapper {
                max-width: 100%;
                margin: 0;

                .page-title-wrapper {
                    text-align: center;

                    .page-title {
                        font-size: 60px;
                        font-weight: normal;
                        letter-spacing: 0.25px;
                        color: #1c2028;
                        margin: 80px 0 45px 0;
                    }
                }

                .category-description {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }

    &.page-products {
        .products-grid {
            .product {
                &-items {
                    display: grid;
                    grid-template-columns: 25% 25% 25% 25%;
                    margin: 0 -5px;

                    @media screen and (max-width: $screen__m) {
                        grid-template-columns: 50% 50%;
                        justify-items: center;
                        margin: 0;
                    }
                }

                &-item {
                    width: 100%;

                    &-details {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

