.page-product-material-list {

    .material-products {
        .product-item-details .material-cost-wrapper {
            min-height: 42px;

            .material-cost {
                @include material-cost(
                        "$$$$$",
                        $font-size: "14px",
                        $line-height: "normal"
                );

                @media screen and (max-width: $screen__m) {
                    &:after,
                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .swatch-attribute.material_color,
        .swatch-attribute.color {
            .swatch-option.color,
            .swatch-option.image{
                @include max-screen($screen__m) {
                    max-width: 30px !important;
                    max-height: 30px !important;

                    &:after {
                        max-width: 21px;
                        max-height: 21px;
                    }
                }

                @include max-screen($screen__xs) {
                    max-width: 25px !important;
                    max-height: 25px !important;
                    margin: 0 5px 5px 0;

                    &:after {
                        max-width: 16px;
                        max-height: 16px;
                        border-width: 1px;
                    }
                }
            }

            .show-more {
                margin-left: 5px;

                @include max-screen($screen__m) {
                    line-height: 30px;
                    font-size: 12px;
                }

                @include max-screen($screen__xs) {
                    line-height: 25px;
                    margin-left: 0;
                }
            }
        }
    }
}


.page-product-material {
    .page-main {
        padding: 0;
    }

    // page material flex order for sections
    .column.main {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);

        .product-top,
        .attr-under-info,
        .material-description,
        .appointment,
        .blog-widget-recent,
        .related-posts,
        .related-products,
        .blog-widget-recent-masonry {
            width: 100%;
        }

        .product-top {
            order: 1;

            .product-info-main {
                .product-social-links {
                    display: none;
                }
            }
        }

        .attr-under-info {
            order: 2;

            @media screen and (max-width: $screen__m) {
                display: none;
            }
        }

        .additional-attributes-wrapper {
            order: 3;
        }

        .blog-widget-recent {
            order: 5;
            margin: 140px auto 0 auto;

            .title {
                display: none;
            }
        }

        .blog-widget-recent-masonry {
            order: 4;
            max-width: 1440px;
            margin-top: 0;

            .post-holder {
                max-width: 430px;
            }
        }

        .related-products {
            order: 6;
        }

        .appointment {
            order: 7;

            .inner-block {
                padding: 0 40px;

                @media screen and (max-width: 768px) {
                    padding: 0;
                }
            }
        }

        .related-posts {
            order: 8;
        }
    }

    .product-collapsible-info {
        .details {
            h1, h2, h3 {
                font-size: 14px;
                margin: 0;
                padding: 0;
                font-weight: bold;
            }

            ul {
                list-style: none;
                padding-left: 0;

                li {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .additional-attributes-wrapper {
        margin: auto;

        .additional-attributes {
            padding: 0 20px;
        }

        .inner-block {

            &.choice_of_profiling_door_model {
                display: none;
            }

            .data {
                position: relative;
                min-height: 20px;

                @media not all and (min-resolution:.001dpcm) { 
                    @supports (-webkit-appearance:none) {
                        bottom: 68px;
                    }
                }

                &.material_cost {
                    @include material-cost(
                            "$$$$$",
                        $inner-selector: ".value",
                        $line-height: "normal",
                        $font-size: "24px"
                    );
                }

                .fill-stars-wrapper,
                .empty-stars-wrapper {
                    svg {
                        display: inline-block;
                        overflow: visible;
                        width: 25px;
                    }
                }

                .fill-stars-wrapper {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 10;
                    @include lib-vendor-prefix-display(flex);
                }

                .empty-stars-wrapper {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 9;
                    @include lib-vendor-prefix-display(flex);
                }
            }
        }
    }

    .related-products {
        overflow: hidden;
        padding: 0;
    }

    .product-options-bottom {
        .material-cost-wrapper {
            min-height: 50px;

            .material-cost {
                @include material-cost("$$$$$");
            }
        }


        .action.towishlist {
            @extend .primary--border;
            background: transparent;
            line-height: 50px;
            max-width: 340px;
            width: 100%;
            padding: 0;
            box-sizing: border-box;

            @media screen and (max-width: $screen__m) {
                max-width: 100%;
            }

            &:after {
                display: none;
            }
        }
    }

    .product-addto-links {
        justify-content: flex-end;
    }


    .attr-under-info {
        max-width: 1440px;
        margin: 0 auto;

        .attr-content-title {
            color: $c-dark;
            font-size: 26px;
            letter-spacing: 1px;
            line-height: 34px;
            text-align: center;
            margin: 109px 0 40px 0;

            @media screen and (max-width: $screen__m) {
                margin: 40px 0;
                //padding: 0 20px;
                padding: 0;
                box-sizing: border-box;
            }
        }

        .attr-content {
            text-align: center;

            @media screen and (max-width: $screen__m) {
                //padding: 0 20px;
                padding: 0;
            }

            .amasty-attr {
                @include lib-vendor-prefix-display(flex);
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .item {
                    margin: 0 55px;
                    max-width: 220px;

                    @media screen and (max-width: $screen__m) {
                        margin: 0 auto;
                        width: 47%;
                    }

                    .title {
                        color: $c-dark;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 21px;
                        padding: 30px 0;

                        &:first-letter {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }


    .material-description {
        max-width: 880px;
        width: 100%;
        margin: 135px auto;

        @media screen and (max-width: $screen__m) {
            margin: 40px auto;
        }

        .title {
            color: $c-dark;
            font-size: 26px;
            letter-spacing: 1px;
            line-height: 34px;
            text-align: center;
            margin-bottom: 50px;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 20px;
            }
        }

        .content {
            @media screen and (max-width: $screen__m) {
                padding: 0 20px;
            }

            h1, h2, h3 {
                font-size: 14px;
                margin: 0;
                padding: 0;
            }

            ul {
                list-style: none;
                padding-left: 0;

                li {
                    &:before {
                        content: "•";
                        padding-right: 20px;
                    }
                }
            }
        }
    }



    .blog-widget-recent .post-list .post-holder .post-content .post-description .post-info,
    .block.related .post-list .post-holder .post-content .post-description .post-info {
        display: block;

        .post-posed-date {
            .label {
                display: none;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .appointment,
        .related-posts.block.related {
            margin: 0;
            padding: 0;
        }
    }
}
