.breadcrumbs {
    margin: 16px 0;

    .items {
        display: flex;
    }

    .item {
        font-size: 12px;
        text-align: center;
        color: $c-dark;

        &:after {
            content: "/";
            padding: 0 10px;
            white-space: pre;
        }

        &:last-of-type {

            &:after {
                content: none;
            }
        }
    }
}
