/* CUSTOM CSS ARMOIRES CUISINES ACTION */


/* COULEUR PAGES INSPIRATION OU BOUTIQUE */
.catalog-category-view { background: #fff!important; }

/* HOME PAGE CACHER BREADCRUMB */
.cms-index-index .breadcrumbs { display:none; }

/* PARAGRAPHE */
.service-section .service-block .inner-block.text-block .title { line-height:27px!important; }

/* NAVIGATION PRINCIPALE */
.header.content .navigation > ul > li.navigation-static-link > a, a.level-top.shop-online>span { margin: 0 15px!important; }

/* HOME PAGE BANNIERE */
.home-full-width-banner .inner-block { max-width: 700px!important; }
.home-full-width-banner .inner-block .content-block .title { margin-bottom: 25px!important; }

/* PROMO BANNER */
.text-promo-banner { background-color: cccccc!important; }

/* TABLE ECHELLE DE PRIX */
.nomargin p { margin-bottom:0px!important; }
.nomargin h3 { margin:0px!important; color:#fff!important; }

/* CHAMPS inspi_cost INSPIRATIONS */
/* .inspirations-post-view .inspiration-content-wrapper .inspiration-cost-content { max-width: 170px!important; } */
/* .inspirations-post-view .inspiration-content-wrapper .inspiration-cost-content:after { display:none!important; } */
/* .inspirations-post-view .inspiration-content-wrapper .inspiration-cost-content .count.text { text-align: center!important; font-size: 22px!important; line-height: 27px } */

/* LARGEUR W950 PLUS PETITE */
.w650 { max-width: 650px; margin: 0 auto; }
.w750 { max-width: 760px; margin: 0 auto; }
.w850 { max-width: 850px; margin: 0 auto; }
.h2w650 { max-width: 650px; margin: 17px auto; }

/* IMAGES PROFIL MATERIAUX */
.page-product-material .attr-under-info .attr-content .amasty-attr .item { margin: 0 10px!important; max-width: 180px!important; }

/* BOUTON RDV ET DEMARREZ VOTRE PROJET */
.header.content .navigation-projects { padding: 14px 16px!important; margin-left: 20px!important; }
.rdv { background-color: #567E5F!important; color:#ffffff!important; }

/* BARRE TOP HEADER CHANGEMENT COULEUR VERT */
.top-header-message { background-color: #567E5F!important; }

/* TEXTE CREDIT */
.credit { font-style: italic; font-weight: 300; }

/* MARGES BLOCH AVEC PDF TELECHARGEMENT */
.cms-page-view .text-files-block { margin: 80px 0!important; }

/* TITRE H1 H2 */
h1 { margin-bottom:15px!important; }
h2 { line-height: 36px!important; }
h4 { font-weight: 500!important; line-height: 28px!important; color:#567E5F!important; }

/* TEXTE LEGENDE PHOTO BLOG */
.legende { text-align:center; margin:10px 30px 50px; }

/* TEXTE LEGENDE PHOTO BLOG MOBILE */
@media screen and (max-width: 768px) {
.legende { margin:10px 15px 30px!important; font-size:14px!important; }
}

/* TEXTE INSERT ENCADRE */
.insert { padding: 25px; border: 1px solid; text-align: center; margin: 45px auto; }

/* TABLEAU FORMULAIRE DEMANDE SAV */
table.sav tbody tr:nth-child(2n) { background:none!important; }

/* TABLEAU LEAN AND CLEAN 1 CUISINE 3 PRIX */
table.tableauclean tr { border-bottom:1px solid #cccccc!important; }
table.tableauclean p { margin:7px 0!important; }

@media screen and (max-width: 1024px) {
table.tableauclean p { font-size:8px!important; line-height:10px!important; }
table.tableauclean h3 { font-size:12px!important; line-height:14px!important; margin: 10px 0!important; }
}

/* BOUTON */
.action.primary, button, .action-gift { padding: 17px 20px!important; }


/* SLIDER */
.home__slider-banner .content .text .title { line-height:55px!important; font-size: 45px!important; }

/* FAQ FOIRE AUX QUESTION */
.foire table tbody tr td { font-weight: normal!important; }
.faq h3 { color:#567E5F!important; }
.faqtop { margin-top:20px; }
.clear { clear:both; }
.faq { text-align: center!important; margin-top:50px; }
.faqicon { margin:0 auto!important; }


/* GUIDE INSTALLATION */
.cms-guide-installation .home__slider-banner .content .text .q_b-primary, .cms-guide-installation .home__slider-banner .content .text .q_b-primary { display:none!important; }



/* CACHER BOUTON 1 CUISINE 3 PRIX SLIDER */
.blog-post-1-kitchen-3-costs .home__slider-banner .content .text .q_b-primary, .blog-post-1-cuisine-3-prix .home__slider-banner .content .text .q_b-primary { display:none!important; }

@media screen and (max-width: 1024px) {
.home__slider-banner .slide:after { background-color:rgba(0, 0, 0, 0)!important; }
}

@media screen and (max-width: 1024px) {
/* .home__slider-banner .content .text .q_b-primary { display:none!important; } */
}

/* CACHER TITRE CATEGORIES BLOG PAGES */
.blog-page .page-title-wrapper .page-title { display:none!important; }

/* AFFICHER TITRE BLOG PAGE */
.blog-index-index .page-title-wrapper .page-title { display:inline-block!important; }
.blog-post-view .page-title-wrapper .page-title { display:inline-block!important; }

/* MARGE SIDEBAR MOBILE */
.blog-page .sidebar .widget.block-categories { margin-bottom: 20px!important; padding: 0 20px!important; }
.block-list-posts { margin-bottom: 20px!important; padding: 0 20px!important; }
.blog-search { padding: 0 20px!important; }

/* MARGE TOP VIDEO PAGE INSPIRATION */
.inspirations-post-view .inspiration-content-wrapper .inspiration-vimeo-wrapper { margin-top: 50px!important; }

/* VIGNETTE PHOTO PAGES INSPIRATION */
.product-image-photo { max-width: 75%!important; }
.category-inspirations .product-image-photo { max-width: 100%!important; }


/* BUTTON BLOG MARGIN */
a.action.primary { margin: 10px 0!important; }

/* ITEM PAGE INSPIRATION MOBILE */
@media screen and (max-width: 768px) {
.inspirations-post-view .inspiration-content-wrapper .inspiration-attributes-wrapper > div { width: 30%!important; }
.inspirations-post-view .inspiration-content-wrapper .inspiration-shape-content, .inspirations-post-view .inspiration-content-wrapper .inspiration-style-content { font-size: 15px!important; }
.inspirations-post-view .inspiration-content-wrapper .inspiration-cost-content:after, .inspirations-post-view .inspiration-content-wrapper .inspiration-cost-content .count.text { font-size: 15px!important; margin-left: 25px!important; }
.inspirations-post-view .inspiration-content-wrapper .inspiration-cost-content .count.text { font-size: 15px!important; }
.home__slider-banner .content .text .title { line-height:32px!important; font-size: 30px!important; }
}

/* BLOCK PROMOTION MOBILE */
@media screen and (max-width: 768px) {
.text-promo-banner { padding: 10px 0!important; }
}

/* GROSSEUR TEXTE COPYRIGHT MOBILE */
@media screen and (max-width: 768px) {
.footer.content .copyright { font-size: 12px!important; }
}

/* CENTRER IMAGE ENTETE BLOG */
.post-ftimg-hld { text-align:center!important; }

/* DESCRIPTION INSPIRATION CATEGORIE */
.category-view .category-description-image-wrapper { margin-bottom: 0px!important; }
.category-view .category-description-image-wrapper .category-description-wrapper { max-width: 600px; }
.inspirations-page-view .category-view .category-description-image-wrapper .category-description-wrapper .page-title-wrapper .page-title { font-size: 32px!important; letter-spacing: 0.5px!important; text-align: left!important; }
.inspirations-page-view .category-view .category-description-image-wrapper .category-description-wrapper .category-description { text-align: left!important; font-size: 14px!important; font-weight: normal!important; }
.inspirations-page-view .category-view .category-description-image-wrapper .category-description-wrapper { max-width: 600px!important; margin-right: 90px!important;}
.inspirations-page-view .category-view .category-description-image-wrapper .category-image { margin-top: 50px!important; }
.inspirations-post-view .inspiration-content-wrapper .inspiration-countertop-color-wrapper, .inspirations-post-view .inspiration-content-wrapper .inspiration-shape-wrapper, .inspirations-post-view .inspiration-content-wrapper .inspiration-style-wrapper, .inspirations-post-view .inspiration-content-wrapper .inspiration-cost-wrapper { max-width: 170px!important; }

/* MARGES AUTOUR BLOC ADVANTAGES*/
.home-advantages, .advantages { margin: 80px auto!important; }

/* DESCRIPTION INSPIRATION CATEGORIE */
@media screen and (max-width: 768px) {
.inspirations-page-view .category-view .category-description-image-wrapper .category-description-wrapper .category-description { padding-bottom: 3rem!important; text-align: center!important; }
.inspirations-page-view .category-view .category-description-image-wrapper .category-description-wrapper { max-width: 600px!important; margin: 0 1.6rem!important; }
}

/* DOCUMENTATION MOBILE COLONNE PLEINE LARGEUR */
@media screen and (max-width: 768px) {
.home-advantages .advantage, .advantages .advantage { max-width: 100%!important; }
}

/* IPHONE APPAREIL MOBILE */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {


/* BREADCRUMB APPAREIL MOBILE */
.breadcrumbs { display:none; }

/* CHAMPS FORMULAIRE CONTOUR */
input[type="text"], input[type="number"], input[type="email"], input[type="password"] { border: 1px solid #e9e9eb!important; }

/* LARGEUR CHAMPS FORMULAIRE MOBILE */
.savmobile input { width:60%!important; }
.savmobile input.inputmedium { width:80%!important; }
.savmobile input.inputlarge { width:100%!important; }

/* HEIGHT */
.20 { min-height:10px; }
.40 { min-height:20px; }
.60 { min-height:30px; }
.80 { min-height:40px; }
.100 { min-height:50px; }

}


/* MODULE AFFICHAGE RH OFFRE EMPLOI */
.product-collapsible-info__item .content ul li { text-align: left; }

/* FOOTER OTHER LINKS */
.footer__small-links { font-size: 14px!important; }

/* ACCORDION ET ICONES ET AUTRES PAGE CARRIERE */
.product-collapsible-info__item .content ul { padding-left: 55px!important; text-align: left!important; list-style: square!important; }
.cms-careers .text-files-block .inner-block .description, .cms-careers .text-files-block .inner-block .icons-wrapper-block, .cms-carrieres .text-files-block .inner-block .description, .cms-carrieres .text-files-block .inner-block .icons-wrapper-block { max-width: 800px!important; padding-bottom: 0px!important; }
.cms-careers .home-services .row .service .content .title, .cms-carrieres .home-services .row .service .content .title { color: #1c2028; font-size: 20px; letter-spacing: 1px; line-height: 28px; margin-top: 20px; padding:0 10px; }
.cms-careers .home-services .row, .cms-carrieres .home-services .row { margin: 70px auto!important; }
.collapsible-info ul li { text-align: left!important; }

/* iPhone */
@media screen and (max-width: 768px) {
	.home-services .row:last-child { display: block!important; }
	.home-services .row .service:not(:last-child) { margin-bottom: 20px!important;  margin-top: 20px!important; }
	.collapsible-info ul li { text-align: left!important; }
	.product-collapsible-info__item .content ul { padding-left: 25px!important; }
	.cms-page-view .text-files-block .inner-block .icons-wrapper-block .icon-block { margin: 10px 80px!important; }
	.w950 .double { margin-top:-70px!important; }
	.inspirations-post-view .inspiration-top-content .page-title-wrapper .page-title { font-size: 20px!important; line-height: 23px!important; }
	.home-full-width-banner .inner-block .content-block .title { margin-bottom: 25px!important; font-size: 20px!important; line-height: 22px!important; }
	.home-full-width-banner .inner-block .content-block .action.primary { padding: 10px 15px!important; }
	.header.content .navigation .navigation-projects { margin-left: 0px!important; }

/* HEIGHT */
.20 { min-height:10px; }
.40 { min-height:20px; }
.60 { min-height:30px; }
.80 { min-height:40px; }
.100 { min-height:50px; }

}
 
 
 
/* iPad Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
	.header.content .navigation .navigation-projects { margin-left: 0px!important; }
}

/* iPad Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
.header.content .navigation-projects { padding: 14px 10px!important; margin-left: 15px!important; font-size:10px!important; letter-spacing:0px!important; }

}