.form.wishlist.items {
    .actions-toolbar {
        @extend .abs-reset-left-margin;
    }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {

    .action.towishlist {
        @extend .button-as-link;
        @extend .secondary--underline-black;
    }
}

.product-items,
.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            @extend .abs-actions-addto-gridlist;
            @include lib-icon-font-symbol(
                $icon-wishlist-empty
            );
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: $font-weight__bold;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            &-name {
                margin-top: 0;
                min-height: 40px;
            }

            .price-box {
                margin: 0;
                padding: 0;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .box-tocart {
                .field.qty {
                    display: none;
                }

                input.qty {
                    @extend .abs-input-qty;
                    height: 32px;
                }

                .product-item-actions {
                    margin: 0;

                    .actions-primary {
                        width: 100%;
                        display: block;

                        .action.tocart {
                            width: 100%;
                            padding: 17px 0;
                            white-space: normal;
                        }
                    }
                }
            }

            .product-item-actions {
                text-align: center;
                margin-top: 18px;

                .action.edit {
                    display: none;
                }

                .btn-remove.delete {
                    position: relative;
                    color: $c-bluey-grey;
                    padding-bottom: 2px;
                    font-size: 1.4rem;
                    display: inline-block;

                    &:after {
                        content: "";
                        display: block;
                        height: 1px;
                        left: 0;
                        right: 0;
                        bottom: -2px;
                        background-color: $c-bluey-grey;
                        transform: scaleX(1);
                        transition: transform 0.24s ease;
                    }

                    &:hover {
                        text-decoration: none;
                        color: $c-slate;

                        &:after {
                            transform: scaleX(0.3);
                            background-color: $c-slate;
                        }
                    }

                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .products-grid.wishlist {
        margin-bottom: $indent__l;
        @include lib-vendor-prefix-display(flex);
        flex-wrap: wrap;

        .product {
            &-item {
                position: relative;

                &-photo {
                    margin-bottom: 10px;
                }

                &-name {
                    @include lib-font-size(12);
                    padding: 0;
                }

                &-actions {
                    display: block;
                    text-align: center;
                }

                .price-box {
                    .price {
                        font-size: 12px;
                    }
                }

                .box-tocart {
                    .stock {
                        margin-top: 7px;
                    }
                }

                .giftregisty-dropdown,
                .field.qty {
                    display: none;
                }
            }
        }
    }

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {
                width: calc(50% - 20px);
                margin-right: 20px;
                margin-bottom: 33px;
                display: inline-block;
                box-sizing: border-box;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &-info {
                    width: auto;
                }
            }
        }

        .actions-toolbar {
            display: none !important;
        }
    }
}

@include min-screen($screen__s) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-bottom: $indent__base;
            }

            .product-item-actions {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid.wishlist {
        .product {
            &-item {
                &-tooltip {
                    display: inline-block;
                }

                &-actions {
                    margin: $indent__s 0 0;
                }

                .fieldset {
                    .field.qty {
                        margin-bottom: $indent__s;
                        padding-right: $indent__s;

                        .label {
                            width: auto;
                        }
                    }
                }

                .box-tocart {
                    .actions-primary {
                        margin: 0;
                    }

                    .stock {
                        margin: $indent__base 0 0;
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .product {
            &-item {
                &-info {
                    width: 240px;
                }
            }
        }

        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    display: none;

                    @extend .abs-reset-left-margin-desktop;

                    .primary {
                        margin: 0;
                        @include lib-vendor-prefix-display(flex);
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;

                        .action {
                            max-width: 240px;

                            &:not(:last-child){
                                margin-right: 10px;
                            }
                        }
                    }

                    .secondary {
                        margin-top: 25px;
                    }
                }
            }
        }

        .wishlist-toolbar {
            margin-top: 40px;
        }
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 3 * 33.3333%) / 2);
                padding: 0;
                width: 32.3333%;
                display: inline-block;
                box-sizing: border-box;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}
