//
//  Product Lists
//  _____________________________________________
.catalog-category-view {
    .breadcrumbs {
        @media screen and (max-width: $screen__m) {
            display: none;
        }
    }

    .page-main > .page-title-wrapper {
        text-align: center;
        padding: 30px;
        display: none;

        @media screen and (max-width: $screen__m) {
            display: block;

            .page-title {
                padding: 0;
                margin: 0;
            }
        }
    }
}
.category-view {
    @include lib-vendor-prefix-display(flex);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 70px;

    @media screen and (max-width: $screen__m) {
        margin-bottom: unset;
        margin-top: 0;
    }

    .category-description-image-wrapper {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        @media screen and (max-width: $screen__m) {
            margin: 0 -20px 30px -20px;
            flex-direction: column-reverse;
        }

        .category-description-wrapper {
            max-width: 400px;
            margin-right: 90px;

            @media screen and (max-width: $screen__m) {
                margin: 0 1.6rem;
            }

            .page-title-wrapper{
                .page-title{
                    font-size: 32px;
                    letter-spacing: 0.5px;
                    color: $c-dark;
                }

                @media screen and (max-width: $screen__m) {
                    display: none;
                }
            }

            .category-description {
                margin-top: 30px;
                font-size: 14px;
                line-height: 1.71;
                color: $c-dark;

                @media screen and (max-width: $screen__m) {
                    padding-bottom: 3rem;
                    text-align: center;
                    border-bottom: 1px solid #e9e9eb;
                }
            }
        }

        .category-image {
            max-width: 890px;
        }
    }

    .category-cms {
        width: 100%;
        background: $c-dark-green;

        @media screen and (max-width: $screen__m) {
            display: none;
        }

        ol {
            text-align: center;
            margin: 22px 0;

            li {
                display: inline-block;
                font-size: 14px;
                letter-spacing: 0.5px;
                text-align: center;
                text-transform: uppercase;
                color: $c-white;
                margin-bottom: 0;
                padding: 0;

                &:not(:last-child) {
                    margin-right: 40px;
                }
            }
        }
    }

}

.products {
    margin: $indent__l 0;
}

.products-grid .items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin: 0 -5px;

    @media screen and (max-width: $screen__m) {
        grid-template-columns: 50% 50%;
        justify-items: center;
        margin: 0;
    }

    > .item {
        padding: 0 5px;


        @media screen and (max-width: $screen__m) {
            &:nth-child(2n - 1) {
                padding-right: 10px;
            }
            &:nth-child(2n) {
                padding-left: 10px;
            }
        }
    }
}

.product {

    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        max-width: 325px;

        @media screen and (max-width: $screen__m) {
            max-width: 150px;
        }

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            font-size: 14px;
            line-height: 1.43;
            color: $c-dark;
            padding: 7px 0 12px 5px;

            @media screen and (max-width: $screen__m) {
                font-size: 12px;
            }
        }

        &-info {
            max-width: 100%;
        }

        .product-item-details {
            margin-bottom: 30px;
        }

        &-actions {
            display: none;

            .actions-secondary {

                & > .action {
                    @extend .abs-actions-addto-gridlist;

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .price-box {
            padding-bottom: 27px;

            @media screen and (max-width: $screen__m) {
                padding-bottom: 0;
            }

            .price {
                display: none;
                font-size: 14px;
                white-space: nowrap;
                color: $c-dark;

                @media screen and (max-width: $screen__m) {
                    font-size: 12px;
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.price-container {

    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {

        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {

    .products-list .product {

        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {

    .product {

        &-item {

            &-actions {
                display: block;

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {

        .products-grid {

            .product-item {
                width: 100%;
            }
        }
    }
}

.page-product-material-list {
    .toolbar-products {
        .toolbar__center {
            margin: 0 auto;
        }

        .toolbar__right {
            display: none;
        }
    }

    .products-grid .product-item {
        width: 100%;
        max-width: 245px;
    }
}
