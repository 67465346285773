//  Newsletter subscription
.block.newsletter {
    margin: 100px auto 0;
    max-width: 600px;
    padding: 0 30px;
    box-sizing: border-box;

    .form.subscribe {
        width: 100%;
        position: relative;

        .actions {
            position: absolute;
            right: 0;
            top: 0;

            .action.subscribe {
                @include lib-icon-font(
                    $_icon-font-content     : $icon-arrow-right-thin,
                    $_icon-font-size        : 18px,
                    $_icon-font-line-height : 34px,
                    $_icon-font-text-hide   : true,
                    $_icon-font-position    : after,
                    $_icon-font-display     : block
                );
                background-color: transparent;
                border: none;
                color: $c-black;
                transition: transform 0.24s ease;
                padding: 7px 15px;

                &:hover {
                    background-color: transparent;
                    transform: translateX(5px);
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .title {
        text-align: center;
        font-size: 3.2rem;
        line-height: 1.5;

        @media screen and (max-width: $screen__m) {
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: 0.25px;
            text-align: center;
        }
    }

    .fieldset {
        margin: 0;
        padding: 0;
    }

    .field {
        margin: 0;

        .control {
            display: block;

            input {
                box-shadow: none;
                border-bottom: 1px solid $c-black;
                border-radius: 0;
                position: relative;
                padding-left: 0;
                padding-right: 45px;
                background: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
    }

    .label {
        @extend .abs-visually-hidden;
    }
}
