.tooltip.wrapper {
    @include lib-tooltip(
        $_tooltip-position        : bottom,
        $_tooltip-selector-content: '.tooltip.content',
        $_tooltip-selector-toggle : '.tooltip.toggle'
    );

    .tooltip.content {

        dl {
            margin-bottom: 0;
        }

        dd {
            white-space: normal;
        }

        .subtitle {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .label {
            margin-top: $indent__s;

            &:first-child {
                margin-top: 0;
            }
        }

        .values {
            margin: 0;
        }
    }
}

.ui-tooltip {
    position: absolute;
    z-index: 9999;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {

    .tooltip.wrapper {

        .tooltip.content {
            min-width: 10rem;
        }
    }
}
