.sitemap-index-index {
    .page-main {
        max-width: $layout__max-width;
        margin: 0 auto;

        @media screen and (max-width: $screen__m) {
            .page-main {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .sitemap-container {
        @include lib-vendor-prefix-display(flex);
        max-width: calc(#{$layout__max-width} / 2);
        margin: 50px auto 0;

        @media screen and (max-width: $screen__m) {
            flex-wrap: wrap;
            margin: 30px auto 0;
        }

        .sitemap-column {
            width: 50%;

            @media screen and (max-width: $screen__m) {
                width: 100%;
            }

            &--category {

                > ul > li {
                    margin-top: 20px;
                }

                > ul > li > a {
                    margin-bottom: 10px;
                    display: block;
                }
            }
        }

        h2 {
            color: $c-dark;
        }

        ul {
            list-style: none;
            padding: 0
        }
    }
}
