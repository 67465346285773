.inspirations-post-view {
    .inspiration-top {
        &-wrapper {
            margin: 0 -40px;
            position: relative;
            overflow: hidden;

            @media screen and (max-width: $screen__m) {
                margin: 0;
            }

            .inspiration-media {
                &__image {
                    margin: -10px 0; 

                    &:after {
                        content: '';
                        background: rgba(0,0,0,0.1);
                        position: absolute;
                        top: -4px;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        width: 100%;
                    }
                }

                .owl-stage-outer .owl-stage {
                    @media screen and (max-width: $screen__m) {
                        .inspiration-media__image {
                            img {
                                height: 657px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }


            .owl-dots {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 55px;
                z-index: 90;

                @media screen and (max-width: $screen__m) {
                    bottom: 25px;
                }
            }
        }

        &-content {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 9;
            @include lib-vendor-prefix-display(flex);
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .inspiration-collection {
                color: $c-white;
                font-size: 14px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                margin-bottom: 19px;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 16px;
                }
            }

            .page-title-wrapper {
                .page-title {
                    font-size: 60px;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: $c-white;
                    margin-bottom: 19px;
                    line-height: normal;

                    @media screen and (max-width: $screen__m) {
                        font-size: 32px;
                        line-height: 1.06;
                        letter-spacing: 0.5px;
                        margin-bottom: 10px;
                    }
                }
            }

            .inspiration-buttons-wrapper {
                @include lib-vendor-prefix-display(flex);
                flex-direction: row-reverse;

                .action {
                    width: 41px;
                    height: 41px;
                    border-radius: 100%;
                    border: solid 1px $c-white;
                    font-size: 0;
                    margin: 0 7px;

                    @media screen and (max-width: $screen__m) {
                        width: 30px;
                        height: 30px;
                    }

                    &.towishlist {
                        @include lib-vendor-prefix-display(flex);
                        justify-content: center;
                        align-items: center;

                        @include icomoon("heart", "before", true, true) {
                            font-size: 16px;
                            color: $c-white;
                        }
                    }

                    &.mailto {
                        @include lib-vendor-prefix-display(flex);
                        justify-content: center;
                        align-items: center;

                        @include icomoon("share", "before", true, true) {
                            font-size: 16px;
                            color: $c-white;
                        }
                    }

                    &:hover {
                        opacity: .7;
                        text-decoration: none;
                    }
                }
            }
        }


    }

    .inspiration-content-wrapper {
        max-width: 900px;
        margin: 80px auto;
        text-align: center;

        @media screen and (max-width: $screen__m) {
            margin: 50px auto;
            padding: 0 20px;
        }

        .short-description {
            font-size: 32px;
            letter-spacing: 0.5px;
            color: $c-dark;
            margin: 0;

            @media screen and (max-width: $screen__m) {
                font-size: 28px;
                line-height: 1.21;
            }
        }

        .inspiration-location {
            font-size: 14px;
            color: $c-bluey-grey;
            margin: 30px 0 0 0;

            @media screen and (max-width: $screen__m) {
                margin: 20px 0 0 0;
            }
        }

        .description {
            margin: 30px 0 0 0;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: normal;
            color: $c-dark;

            @media screen and (max-width: $screen__m) {
                margin: 20px 0 0 0;
            }
        }


        .inspiration {
            &-attributes-wrapper {
                margin-top: 60px;
                @include lib-vendor-prefix-display(flex);
                justify-content: center;
                flex-wrap: wrap;

                @media screen and (max-width: $screen__m) {
                    > div {
                        width: 50%;
                        box-sizing: border-box;
                    }
                }
            }

            &-swatch-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-around;

                @media screen and (max-width: $screen__m) {
                    flex-direction: column;
                    align-items: center;
                }
            }

            &-countertop-color,
            &-shape,
            &-style,
            &-cost {
                &-wrapper {
                    text-align: center;
                    margin-bottom: 60px;
                    width: 100%;
                    max-width: 145px;
                    padding: 0 15px;
                    box-sizing: content-box;

                    &:not(:last-child) {
                        border-right: solid 1px $c-cloudy-blue;

                        @media screen and (max-width: $screen__m) {
                            border: none;
                        }
                    }

                    @media screen and (max-width: $screen__m) {
                        padding: 0;
                        margin-bottom: 30px;
                        max-width: 255px;
                    }
                }

                &-title {
                    font-size: 16px;
                    font-weight: 500;
                    color: $c-dark;
                    margin-bottom: 12px;
                }
            }

            &-shape,
            &-style {
                &-content {
                    font-size: 22px;
                    letter-spacing: 0.5px;
                    color: $c-dark;
                    line-height: normal;
                    word-wrap: break-word;
                }
            }

            &-cost {

                &-content {
                    max-width: 90px;
                    width: 100%;
                    margin: 0 auto;

                    @include material-cost(
                            "$$$$$",
                        $inner-selector: ".count.text",
                        $line-height: "30px",
                        $font-size: "32px"
                    );
                }

            }

            &-countertop-color-images {
                @include lib-vendor-prefix-display(flex);
                justify-content: center;
                height: 48px;

                .image {

                    &:nth-child(odd) {
                        align-self: flex-end;
                        margin-left: -7px;
                        margin-right: -7px;
                    }

                    &:nth-child(even) {
                        align-self: flex-start;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                    }
                }
            }

            &-cabinet {
                &-wrapper {
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media screen and (max-width: $screen__m) {
                        margin-top: 20px;
                    }
                }

                &-title {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: $c-dark;
                    margin-bottom: 20px;
                    width: 200px;
                }

                &-images {
                    @include lib-vendor-prefix-display(flex);
                    justify-content: center;

                    .image {
                        margin: 15px;
                        img {
                            width: 45px;
                            height: 45px;
                            border-radius: 100%;
                        }

                        .text {
                            font-size: 12px;
                            letter-spacing: 0.05px;
                            text-align: center;
                            color: $c-dark;
                            margin-top: 10px;
                            line-height: 18px;
                        }
                    }
                }
            }

            &-vimeo {
                &-wrapper {
                    margin: 125px 0 0 0;
                    position: relative;
                    padding-bottom: 56.25%;
                    height: 0;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }


        .inspiration-other {
            &-title {
                font-size: 32px;
                letter-spacing: 0.5px;
                color: $c-dark;
                margin-top: 60px;
            }

            &-description {
                font-size: 14px;
                line-height: 1.71;
                color: $c-dark;
                margin-top: 60px;

                h1, h2, h3 {
                    font-size: 18px;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .inspiration-masonry-images {
        max-width: 1320px;
        margin: 0 auto;
        columns: 3;
        column-gap: 0;

        @media screen and (max-width: $screen__l) {
            columns: 2;
        }

        @media screen and (max-width: $screen__m) {
            columns: 2;
            column-gap: 5px;
            padding: 0 20px;
        }

        &.one-masonry-image {
            columns: 3;
        }

        &.two-masonry-image {
            display: flex;
            justify-content: center;

            .inspiration-masonry-image {
                padding: 5px;
            }
        }

        .inspiration-masonry-image {
            cursor: pointer;
            margin: 0 0 0 0;
            max-width: 430px;
            width: 100%;
            box-sizing: content-box;
            display: table;

            &:last-child {
                border-top: double transparent 0.1px;
            }

            img {
                width: 99%;
                display:inline-block;
                vertical-align: text-top;
                margin-bottom: 15px;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 5px;
                }

                @media screen and (max-width: 960px) and (min-width: 770px) {
                        width: 97%;
                }

                @media screen and (max-width: 1400px) and (min-width: 1025px) {
                        width: 97%;
                }
            }
        }
    }

    .modal-inspirations {
        display: none;
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
        margin: 0 !important;
        background: #fff;
        z-index: 1000 !important;
        overflow: unset;

        .main-view-image {
            height: 100vh;
            margin: 0 auto;
            display: block;
            text-align: center;

            @media screen and (max-width: 1025px) {
                width: 100%;
                margin: 100px 0 0 0;
            }

            img {
                transform: scale(1);
                transition: all 1s;
                display: block;
                top: 0;
                left: 0;
                width: auto;
                height: auto;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .close-modal, .prevImg, .nextImg, .zoom-in, .zoom-out {
            display: block;
            width: 80px;
            height: 80px;
            background-repeat: no-repeat;
            background-size: auto;
            cursor: pointer;

            @media screen and (max-width: 1025px) {
                width: 40px;
                height: 40px;
                background-position: center;
            }
        }

        .close-modal {
            background-image: url('../images/icons/close_icon.png');
            position: absolute;
            top: 0;
            right: -6px;
        }

        .prevImg {
            position: absolute;
            left: 0;
            background-image: url('../images/icons/prev.png');

            @media screen and (max-width: 1025px) {
                bottom: 2%;
                z-index: 10;
                left: 5px;
                top: unset !important;
            }

            @media screen and (max-width: 650px) {
                bottom: 100px;
            }
        }

        .nextImg {
            position: absolute;
            right: -6px;
            background-image: url('../images/icons/next.png');

            @media screen and (max-width: 1025px) {
                bottom: 2%;
                z-index: 10;
                right: 5px;
                top: unset !important;
            }

            @media screen and (max-width: 650px) {
                bottom: 100px;
            }
        }

        .zoomImg {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;

            .zoom-in {
                background-image: url('../images/icons/zoom_in.png');
            }

            .zoom-out {
                background-image: url('../images/icons/zoom_out.png');
            }
        }

        .modal-masonry-images {
            width: 100%;
            margin: 0 auto;
            background: #fff;
            padding: 10px 0;
            position: absolute;
            bottom: 0;

            @media screen and (max-width: 650px) {
                width: 150px;
                left: 50%;
                margin-left: -75px;
                padding-bottom: 75px;

                &:before {
                    content: "";
                    width: 100vw;
                    height: 150px;
                    position: absolute;
                    bottom: 0;
                    margin-left: calc(50% - 50vw);
                    margin-right: calc(50% - 50vw);
                    background: #fff;
                }
            }

            &.owl-carousel {
                @media screen and (min-width: 650px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .owl-stage-outer {
                margin: auto;
                width: 100%;

                .owl-stage {
                    margin: auto;
                }
            }

            .owl-dots {
                position: relative;

                &.disabled {
                    display: block;
                    visibility: hidden;
                }
            }

            .masonry-image {
                cursor: pointer;
                position: relative;
                overflow: hidden;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: #fff;
                margin: auto;

                @media screen and (min-width: 650px) {
                    margin: 10px;
                }

                &.current-img {
                    border: 1px solid #000;
                }

                img {
                    display: block;
                    width: 80%;
                    position: absolute;
                    top: 50%;
                    left: 3px;
                    height: 80%;
                    transform: translateY(-50%);
                    border-radius: 100%;
                    overflow: hidden;
                }
            }
        }
    }

    .appointment {
        .inner-block .banner-block {
            @media screen and (max-width: $screen__m) {
                &:first-child {
                    display: none;
                }
            }
        }
    }
}
