
@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Hairline',
    $font-weight: 100,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-HairlineItalic',
    $font-weight: 100,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Thin',
    $font-weight: 200,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-ThinItalic',
    $font-weight: 200,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Light',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-LightItalic',
    $font-weight: 300,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-RegularItalic',
    $font-weight: 400,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Medium',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-MediumItalic',
    $font-weight: 600,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Bold',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-BoldItalic',
    $font-weight: 700,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-Black',
    $font-weight: 900,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Sailec/Sailec-BlackItalic',
    $font-weight: 900,
    $font-style: italic
);

//
//  Desktop
//  _____________________________________________

body {
    color: $c-dark;
}

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

h1 {
    font-size: 3.2rem;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 38px;
}

h2 {
    font-size: 2.6rem;
    letter-spacing: 1px;
    line-height: 30px;
    margin: 40px 0 17px 0;
    font-weight: normal;
}

h3 {
    font-size: 2rem;
    letter-spacing: 0.5px;
}

p {
    color: $c-dark;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
}

.items {
    @include lib-list-reset-styles();
}

.medium {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

@include max-screen($screen__m) {

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.4rem;
    }
}

// LISTS
.page-main {
    ul {
        padding-left: 10px;
        list-style-position: inside;

        li {
            font-size: 14px;
            line-height: 24px;
        }
    }
}
