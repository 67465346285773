//
//  Checkout Estimated Total
//  ---------------------------------------------

.opc-estimated-wrapper {
    @extend .abs-no-display-desktop;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px -30px 0 -30px;
    padding: 18px 30px;
    background-color: $c-pale-grey;

    @media screen and (max-width: $screen__m) {
        margin: 30px -20px;
    }

    .estimated-block {
        float: left;

        .estimated-label {
            display: block;

            @include lib-icon-font(
                $_icon-font-content     : $icon-down,
                $_icon-font-size        : 16px,
                $_icon-font-line-height : 16px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );

            @media screen and (max-width: $screen__m) {
                &:after {
                    padding-left: 15px;
                }
            }
        }
    }

    .minicart-wrapper {
        @include lib-icon-font(
            $_icon-font-content     : $icon-cart,
            $_icon-font-size        : 28px,
            $_icon-font-line-height : 1,
            $_icon-font-text-hide   : false,
            $_icon-font-position    : before,
            $_icon-font-display     : block
        );
        display: flex;
        align-items: center;

        .estimated-price {
            margin-left: 5px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .opc-estimated-wrapper {
        display: none;
    }
}
